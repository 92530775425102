<!--
  报表：新建/编辑文件夹
-->
<template>
  <el-dialog
    :visible="newFolderTo"
    :title="$t('label.report.folder') + '：' + header"
    :before-close="newFolderCancel"
    :close-on-click-modal="false"
    width="712px"
  >
    <!-- 顶部 -->
    <div class="title_name">
      <el-form ref="form" :model="form" class="demo-form-inline" label-width>
        <el-form-item
          :label="$t('label.foldername')"
          prop="name"
          :rules="[
            {
              required: true,
              message: this.$i18n.t('vue_label_report_foldernameisrequired'),
              trigger: ['blur'],
            },
          ]"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.foldervisitlimit')">
          <el-select v-model="form.permission" placeholder>
            <el-option :label="$t('label.readonly')" value="0"></el-option>
            <el-option :label="$t('label.readandwrite')" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加公用报表至此文件夹 -->
    <add-common-to-folder
      ref="AddCommonDashboardRef"
      :types="$t('label.visualpage.btype')"
      :dashboardInfo="dashboardInfo"
    />
    <!-- 报表访问权限 -->
    <access-permissions
      ref="DashboardAccessRef"
      :types="$t('label.visualpage.btype')"
      :permissionInfo="permissionInfo"
    />
    <!-- 底部按钮 -->
    <span slot="footer" class="dialog-footer">
      <!-- 取消 -->
      <el-button :loading="savaStatus" @click="newFolderCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <!-- 删除 -->
      <el-button
        type="primary"
        :loading="savaStatus"
        @click="deleteReportFolders"
        v-show="header == $t('pagecreator_page_button_edit')"
        >{{ $t("component_setup_tabs_label_delete") }}</el-button
      >
      <!-- 编辑文件夹时--保存-->
      <el-button
        type="primary"
        :loading="savaStatus"
        @click="updateReportFolders('form')"
        v-show="header == $t('pagecreator_page_button_edit')"
        >{{ $t("label.save") }}</el-button
      >
      <!-- 保存 -->
      <el-button
        type="primary"
        :loading="savaStatus"
        @click="addReportFolders('form')"
        v-show="header == $t('label.brief.new')"
        >{{ $t("label.save") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import * as ReportObjectApi from "../api";
import addCommonToFolder from "@/views/reportDashboardFolder/addCommonToFolder"; // 添加公用报表至此文件夹
import accessPermissions from "@/views/reportDashboardFolder/accessPermissions"; // 报表访问权限

export default {
  props: ["header", "folderId", "newFolderTo", "getFolders", "getDataLists"],
  components: {
    addCommonToFolder,
    accessPermissions,
  },
  data() {
    return {
      savaStatus: false, //点击保存时的状态
      form: {
        name: "", // 文件夹名称
        permission: "", // 访问权限
      },
      dashboardInfo: {
        dashboardList: [], // 未归档公用仪表板
        selectDashboardList: [], // 此文件夹中的仪表板
      },
      permissionInfo: {
        user: "", // 可用共享者
        groupList: [], // 公用小组
        partnerRoleList: [], // 合作伙伴角色
        partnerUserList: [], // 合作伙伴用户
        roleAndSubList: [], // 角色及内部下属
        roleList: [], // 角色
        userList: [], // 用户
        allSelectedRoles: [], //所有已选择的共享者列表
      },
    };
  },
  watch: {
    newFolderTo: function () {
      if (this.newFolderTo) {
        this.form = {}; // 表单置空
        setTimeout(() => {
          this.$refs.AddCommonDashboardRef.form = {
            keyword1: "", // 搜索字段
          };
          this.$refs.AddCommonDashboardRef.tabCurArr = {}; // 清空选中项
          this.$refs.DashboardAccessRef.form = {
            user: "", // 可用共享者
            role: "", // 选择的角色范围
            keyword2: "", // 对共享者的搜索字段
          };
          this.$refs.DashboardAccessRef.allRoles = [];
          this.$refs.DashboardAccessRef.allSelectedRoles = [];
          this.$refs.DashboardAccessRef.tabCurArr = {}; // 清空选中项
        }, 0);
        // 判断是点击编辑还是新建
        if (this.folderId) {
          this.getFolderInfos();
        } else {
          this.getNewReportFolders();
        }
      }
    },
  },
  methods: {
    // 关闭弹框
    newFolderCancel() {
      this.$emit("newFolderCancel");
    },
    // 删除文件夹
    deleteReportFolders() {
      let params = {
        id: this.folderId, // 文件夹id
      };
      this.$confirm(this.$i18n.t("label.ccdocument.isdel.folder"), {
        confirmButtonText: this.$i18n.t("label.ems.confirm"),
        cancelButtonText: this.$i18n.t("button_partner_cancel"),
        type: "warning",
      })
        .then(async () => {
          await ReportObjectApi.deleteReportFolder(params);
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label_tabpage_delsuccessz"),
          });
          this.$emit("newFolderCancel");
          this.getFolders();
          this.getDataLists();
        })
        .catch(() => {
          // this.$message({showClose: true,
          //   type: "info",
          //   message: this.$i18n.t("vue_label_report_notice_cannotdelete"),
          // });
        });
    },
    // 点击新增时获取文件夹信息
    async getNewReportFolders() {
      let { data } = await ReportObjectApi.getNewReportFolder();
      this.form = {
        name: "",
        permission: "0",
      };
      this.dashboardInfo = {
        dashboardList: data.reportList,
        selectDashboardList: data.selectReportList,
      };
      this.permissionInfo = {
        user: "0", // 可用共享者
        groupList: data.groupList,
        partnerRoleList: data.partnerRoleList,
        partnerUserList: data.partnerUserList,
        roleAndSubList: data.roleAndSubList,
        roleList: data.roleList,
        userList: data.userList,
        allSelectedRoles: [],
      };
    },
    // 新建文件夹
    addReportFolders(formName) {
      setTimeout(() => {
        this.savaStatus = false;
      }, 1000 * 4);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.savaStatus = true;
          let folderUser = []; // 文件夹分配的指定用户
          this.$refs.DashboardAccessRef.allSelectedRoles.map((item) => {
            folderUser.push(item.id);
          });
          let folderReport = []; // 文件夹包含的仪表板ids
          this.$refs.AddCommonDashboardRef.selectDashboardList.map((item) => {
            folderReport.push(item.id);
          });
          let params = {
            name: this.form.name,
            purview: this.form.permission,
            viewType: this.$refs.DashboardAccessRef.form.user,
            folderUser: folderUser.join(","),
            folderReport: folderReport.join(","),
          };
          try {
            let data = await ReportObjectApi.addReportFolder(params);
            if (data.result) {
              this.$message.success(
                this.$i18n.t("vue_label_notice_new_success")
              );
            }
          } catch (err) {
            this.savaStatus = false;
          } finally {
            this.savaStatus = false;
          }
          this.getFolders();
          this.getDataLists();
          this.$emit("newFolderCancel");
        } else {
          return false;
        }
      });
    },
    // 点击编辑时获取文件夹信息
    async getFolderInfos() {
      let params = {
        id: this.folderId,
      };
      let { data } = await ReportObjectApi.getFolderInfo(params);
      this.form = {
        name: data.folder.name,
        permission: data.folder.purview,
      };
      this.dashboardInfo = {
        dashboardList: data.reportList,
        selectDashboardList: data.selectReportList,
      };
      let allSelectedRoles = [];
      // 获取已选择的所有用户
      if (data.selectGroupList) {
        data.selectGroupList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectPartnerRoleList) {
        data.selectPartnerRoleList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectPartnerUserList) {
        data.selectPartnerUserList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectRoleAndSubList) {
        data.selectRoleAndSubList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectRoleList) {
        data.selectRoleList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      if (data.selectUserList) {
        data.selectUserList.map((item) => {
          allSelectedRoles.push(item);
        });
      }
      this.permissionInfo = {
        user: data.folder.viewType, // 可用共享者
        groupList: data.groupList,
        partnerRoleList: data.partnerRoleList,
        partnerUserList: data.partnerUserList,
        roleAndSubList: data.roleAndSubList,
        roleList: data.roleList,
        userList: data.userList,
        allSelectedRoles: allSelectedRoles,
      };
    },
    // 编辑文件夹
    updateReportFolders(formName) {
      setTimeout(() => {
        this.savaStatus = false;
      }, 1000 * 4);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.savaStatus = true;
          let folderUser = []; // 文件夹分配的指定用户
          this.$refs.DashboardAccessRef.allSelectedRoles.map((item) => {
            folderUser.push(item.id);
          });
          let folderReport = []; // 文件夹包含的仪表板ids
          this.$refs.AddCommonDashboardRef.selectDashboardList.map((item) => {
            folderReport.push(item.id);
          });
          let params = {
            id: this.folderId,
            name: this.form.name,
            purview: this.form.permission,
            viewType: this.$refs.DashboardAccessRef.form.user,
            folderUser: folderUser.join(","),
            folderReport: folderReport.join(","),
          };
          try {
            let dataObject = await ReportObjectApi.updateReportFolder(params);
            if (dataObject.result) {
              this.$message.success(
                this.$i18n.t("vue_label_notice_edit_success")
              );
            }
          } catch (err) {
            this.savaStatus = false;
          } finally {
            this.savaStatus = false;
          }
          this.getFolders();
          this.getDataLists();
          this.$emit("newFolderCancel");
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 500px;
  overflow-y: auto;

  .border_content {
    display: flex;
    align-items: center;
    .left_info,
    .right_infos {
      padding: 7px 0 10px 9px;
      width: 260px;
      height: 283px;
      overflow-y: auto;
      border: 1px solid #d8dde6;
      border-radius: 4px;
      .each_info {
        color: #333333;
        margin-bottom: 2px;
        padding: 2px;
        cursor: pointer;
      }
      .userActive {
        background-color: #c3c4c5;
        color: #fff;
      }
    }
    .middle_butts {
      margin: 0 10px;
      color: #333333;
      display: flex;
      flex-direction: column;
    }
    div {
      margin-bottom: 8px;
    }
    .el-button--mini,
    .el-button--mini.is-round {
      padding: 4px;
      margin-left: 0;
      margin-bottom: 8px;
    }
  }
}
::v-deep .active {
  background-color: #c3c4c5;
  color: #fff !important;
}
.title_name {
  ::v-deep .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 6px;
      display: flex;
      flex-direction: column;
      .el-select {
        width: 120px;
      }
    }
    .el-form-item:first-child {
      margin-right: 69px;
      .el-input {
        width: 261px;
      }
    }
    .el-form-item__error {
      padding-top: 0;
      padding-left: 2px;
    }
  }
}
::v-deep .el-form {
  .el-form-item__label {
    line-height: 16px;
    color: #242424;
    text-align: left;
  }
}
::v-deep .el-input__inner {
  height: 28px !important;
  // border: 1px solid #dcdcdc;
}
::v-deep .el-input__icon {
  height: 28px;
  line-height: 28px;
}
</style>