<!--
  添加到仪表盘弹框
-->
<template>
  <div>
    <el-dialog
      :visible="funcNotOnlineTo"
      :title="$t('label.report.action.addtodashboard1')"
      width="440px"
      :before-close="funcNotOnlineCancel"
      :close-on-click-modal="false"
    >
      <div class="dialog-body">
        <el-form :rules="rules" ref="ruleForm" :model="form" label-width="">
          <el-form-item
            :label="$t('vue_label_report_selectfolder')"
            prop="folder"
          >
            <el-select
              v-model="form.folder"
              :placeholder="$t('vue_label_report_selectfolder')"
              filterable
              @change="getDashboardList"
            >
              <el-option
                v-for="(item, index) in allFolderList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('label.report.action.addtodashboard3')"
            prop="dashboard"
          >
            <el-select
              v-model="form.dashboard"
              :placeholder="$t('label.report.action.addtodashboard3')"
              filterable
            >
              <el-option
                v-for="(item, index) in dashboardList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="funcNotOnlineCancel">{{
          $t("button_partner_cancel")
        }}</el-button>
        <el-button type="primary" @click="funcNotOnlineConfirm">{{
          $t("label.ems.confirm")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 添加到组件模板弹框 -->
    <ComponentEditor
      ref="ComponentEditorRef"
      :isShowComponentsEditorAlert="isShowComponentsEditorAlert"
      :sourceType="sourceType"
      :dashboardy="dashboardy"
      :dashboardid="form.dashboard"
      :chartInfo="chartInfo"
      :linkageArr="linkageArr"
      :reportIdFromReport="reportIdFromReport"
      @cancel="componentEditorCancel"
      @affirm="componentEditorAffirm"
      @affirmReport="affirmReport"
    />
  </div>
</template>
<script>
import * as request from "../../api.js";
import ComponentEditor from "../../../dashboardObject/dashboardIndex/components/ComponentEditor";
import mixin from "@/mixin/homepageAndDashboardMixin.js";

export default {
  components: {
    ComponentEditor,
  },
  props: {
    funcNotOnlineTo: {
      type: Boolean,
      default: false,
    },
    reportIdFromReport: {
      // 选择的报表
      type: String,
      default: "",
    },
  },
  mixins: [mixin],
  data() {
    return {
      form: {
        folder: "", // 文件夹
        dashboard: "", // 仪表盘
      },
      rules: {
        folder: [
          {
            required: true,
            message: this.$i18n.t("vue_label_report_selectfolder"),
            trigger: "blur",
          },
        ],
        dashboard: [
          {
            required: true,
            message: this.$i18n.t("label.report.action.addtodashboard3"),
            trigger: "change",
          },
        ],
      },
      allFolderList: [], // 所有文件夹
      dashboardList: [], // 所有仪表板列表
    };
  },
  mounted() {},
  methods: {
    funcNotOnlineCancel() {
      this.$emit("funcNotOnlineCancel");
      this.form = {
        folder: "",
        dashboard: "",
      };
      this.dashboardList = [];
    },
    //获取所有文件夹列表
    async getDashboardFolderList() {
      var params = {
        searchKeyWord: "",
      };
      let { data } = await request.getDashboardFolderList(params);
      this.allFolderList = data.allFolderList;
    },
    // 获取仪表板列表
    async getDashboardList(value) {
      this.dashboardList = [];
      this.form.dashboard = "";
      let params = {
        name: "", //搜索内容
        dashboardfolderid: value,
        islightning: "true",
        page: "1",
        pageSize: "1000",
      };
      let { data } = await request.getDashboardList(params);
      this.dashboardList = data.listDashboard;
    },
    // 添加至仪表板弹框中点击确定
    async funcNotOnlineConfirm() {
      this.dashboardid = this.form.dashboard;
      await this.getChartsByDashboardId();
      // 会清空数据，所以需要获取数据来计算位置
      this.getDashboardy();
      this.isShowComponentsEditorAlert = true;
    },
    // 图表添加成功之后调用方法，需要跳转至仪表板首页
    affirmReport() {
      this.$router.push({
        path: `/dashboardObject/dashboardIndex/${this.form.dashboard}`,
        query: {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-body {
  ::v-deep .el-form {
    .el-form-item {
      display: flex;
      margin-bottom: 5px !important;
      .el-form-item__label {
        min-width: 140px;
      }
      .el-select {
        height: 30px;
        line-height: 30px;
        .el-input {
          height: 100%;
          .el-input__inner {
            height: 30px;
            width: 210px;
          }
          .el-input__icon {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>