<!--
  复制报表
-->
<template>
  <el-dialog
    :visible="copyReportTo"
    :title="$t('label.clone')"
    :before-close="copyReportCancel"
    :close-on-click-modal="false"
    width="490px"
  >
    <div class="center_center">
      <el-form ref="form" :model="form" label-width="">
        <el-form-item :label="$t('label.nameofreport')">
          <el-input v-model="form.reportLabel"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.remarkofreport')">
          <el-input
            type="textarea"
            :rows="4"
            placeholder
            v-model="form.reportNote"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('vue_label_report_savelocation')">
          <el-select
            v-model="form.location"
            :placeholder="$t('vue_label_report_selectfolder')"
          >
            <el-option
              v-for="(item, index) in allFolderDatas"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="copyReportCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="copyReportConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { saveCopyReport } from "../api";

export default {
  props: {
    // 控制显示隐藏弹框
    copyReportTo: {
      type: Boolean,
      default: false,
    },
    reportInfos: {
      type: Object,
      default: () => {},
    },
    allFolders: {
      type: Array,
      default: () => [],
    },
    // 获取报表列表方法
    getDataLists: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        reportLabel: "",
        reportNote: "",
        location: "",
      },
      allFolderDatas: [],
    };
  },
  watch: {
    reportInfos: function () {
      this.form = {
        reportLabel: "",
        reportNote: "",
        location: "", //  默认选择‘我的个人自定义’文件夹
      };
      this.form.reportLabel =
        this.reportInfos.reportLabel +
        "_" +
        this.$i18n.t("vue_label_norm_duplicate");
      this.form.reportNote = this.reportInfos.description;
      this.form.location = this.allFolders.find(
        (item) => item.name === this.$i18n.t("message.myselfreport")
      ).id;
      // 对所有可选择的文件夹做权限：可编辑或者是不可编辑但是是我的个人自定义或未归档
      this.allFolderDatas = this.allFolders.filter(
        (item) =>
          item.isedit === "true" ||
          (item.isedit === "false" &&
            (item.name === this.$i18n.t("message.myselfreport") ||
              item.name === this.$i18n.t("label.notfiledpublicreport")))
      );
    },
  },
  methods: {
    copyReportCancel() {
      this.$emit("copyReportCancel");
    },
    // 复制报表
    async copyReportConfirm() {
      let params = {
        description: this.form.reportNote,
        id: this.reportInfos.reportId,
        name: this.form.reportLabel,
        reportCode: "",
        reportFolderId: this.form.location,
        saveAs: true,
      };
      await saveCopyReport(params);
      this.$message.success(
        this.$i18n.t("vue_label_report_notice_copy_success")
      );
      this.getDataLists();
      this.$emit("copyReportCancel");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/bouncedForm.scss";
</style>