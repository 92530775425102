<!-- 报表订阅 -->
<template>
  <el-dialog
    :visible="reportSubscribeTo"
    :title="$t('label.reportsSubscription.entrance')"
    :before-close="reportSubscribeCancel"
    :close-on-click-modal="false"
    width="860px"
    class="report-order"
  >
    <div class="center_center">
      <div class="title-set">
        <!-- 设置  -->
        {{ $t("label.setup") }}
      </div>
      <div class="set-detail">
        <!-- 频率：每日、每周、每月-->
        <div>
          <div>
            {{ $t("label.schedulejob.setup.schedule.frequency") }}
          </div>
          <el-radio-group v-model="frequency" style="margin-bottom: 30px">
            <!-- <el-radio-button label="frequencyday">每日</el-radio-button> -->
            <el-radio-button label="weekly">
              {{ $t("label.everyweek") }}</el-radio-button
            >
            <el-radio-button label="monthly">
              {{ $t("label.schedulejob.setup.schedule.Monthly") }}
            </el-radio-button>
          </el-radio-group>
          <!-- 频率选择为每周 周几  -->
          <el-tabs v-if="frequency == 'weekly'">
            <div>{{ $t("vue_label_report_whatweek") }}</div>
            <el-checkbox-group v-model="checkboxweek" size="small">
              <el-checkbox-button
                v-for="item in weekList"
                :label="item.value"
                :key="item.id"
              ></el-checkbox-button>
            </el-checkbox-group>
          </el-tabs>
          <!-- 频率选择为每月 -->
          <div class="time-detail" v-else-if="frequency == 'monthly'">
            <!-- 时间：相对、特定  -->
            <div>
              <div>
                {{ $t("time") }}
              </div>
              <el-select
                v-model="monthTime"
                @change="changeMonthTime"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
              >
                <el-option
                  v-for="item in monthTimeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 时间：相对 -->
            <div class="div_content" v-if="monthTime == 'relative'">
              <!-- 星期：第几个 -->
              <div>
                <div>
                  {{
                    $t(
                      "label.reportsSubscriptionPage.schedulereport.monthly.week"
                    )
                  }}
                </div>
                <el-select
                  v-model="monthWeek"
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                >
                  <el-option
                    v-for="item in monthWeekList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 天：星期几 -->
              <div>
                <div>
                  {{ $t("Opportunity_Detailed_Phase_Label_004") }}
                </div>
                <el-select
                  v-model="monthDay"
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                >
                  <el-option
                    v-for="item in monthDayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 时间：特定 -->
            <div class="div_content" v-else-if="monthTime == 'given'">
              <!-- 天 ：日期、最后一天 -->
              <div>
                <div>{{ $t("Opportunity_Detailed_Phase_Label_004") }}</div>
                <el-select
                  v-model="specialDay"
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                  @change="chooseDate(specialDay)"
                >
                  <el-option
                    v-for="item in specialDayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 天选择项为日期时，需增加一个日期选项 -->
              <div v-if="specialDay == 'choosespecialday'">
                <div>{{ $t("date") }}</div>
                <el-select
                  v-model="chooseSpecialSomeDay"
                  collapse-tags
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                >
                  <el-option
                    v-for="item in 31"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- 通用时间 -->
        <div class="universal">
          <div class="startend">
            <div>
              {{ $t("D") }}
            </div>
            <el-date-picker
              v-model="startEndDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              :start-placeholder="$t('label.chatter.startDate')"
              :end-placeholder="$t('label.chatter.endData')"
            >
            </el-date-picker>
          </div>
          <div class="time">
            <div>
              <!-- 时间  -->
              {{ $t("time") }}
            </div>
            <el-time-select
              format="HH:mm:A"
              v-model="universalTime"
              :picker-options="{
                start: '8:00',
                step: '00:60',
                end: '23:00',
              }"
              :placeholder="$t('label.appointmentpage.wizard.subtitle2')"
            >
            </el-time-select>
          </div>
        </div>
        <!-- 添加附件 -->
        <div class="enclosure">
          <div>
            <!-- 附件   -->
            {{ $t("label.attachment") }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('label.reportsSubscription.addfile.helptext')"
              placement="top-start"
            >
              <svg class="icon helptext" aria-hidden="true">
                <use href="#icon-bangzhu-changtai"></use>
              </svg>
            </el-tooltip>
          </div>
          <el-button @click="addFileFrameShow">
            <!-- 附加文件  -->
            {{ $t("chatter.attachment") }}
          </el-button>
          <!-- 导出视图 格式化报表 仅限详细信息 格式 -->
          <el-dialog
            width="640px"
            :title="$t('chatter.attachment')"
            :visible.sync="dialogVisible"
            append-to-body
            class="addfile"
          >
            <div>
              <div class="view">
                {{ $t("label_tabpage_exportview") }}
              </div>
              <div class="choose-desc">
                <div
                  :class="
                    chooseformatActive == 'format'
                      ? 'choose-active'
                      : 'desc-border'
                  "
                  @click="chooseformatActivefn"
                >
                  <div class="triangle" v-if="chooseformatActive == 'format'">
                    <div class="check-sign"></div>
                  </div>
                  <div class="desc-title">
                    {{ $t("label.report.export.format.title") }}
                  </div>
                  {{ $t("vue_label_report_formatreport_desc") }}
                  <!-- 导出报表，其中包含报表标头、分组和筛选设置。 -->
                </div>
                <div
                  @click="chooseDetailActivefn"
                  :class="
                    chooseformatActive == 'detail'
                      ? 'choose-active'
                      : 'desc-border'
                  "
                >
                  <div class="triangle" v-if="chooseformatActive == 'detail'">
                    <div class="check-sign"></div>
                  </div>
                  <div class="desc-title">
                    {{ $t("vue_label_report_detailsonly") }}
                  </div>
                  {{ $t("vue_label_report_addFile_detail_Information") }}
                  <!-- 仅导出2000条详细信息行。使用此数据，以进行更多计算，或上载到其他系统。 -->
                </div>
              </div>
              <div class="excel">
                <div>
                  {{ $t("label.ems.format") }}
                </div>
                <el-select
                  v-model="excelValue"
                  disabled
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                >
                  <el-option
                    v-for="item in exceloptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="handleClose">{{
                $t("button_partner_cancel")
              }}</el-button>
              <el-button type="primary" @click="handleConfirm">
                {{ $t("label.ems.confirm") }}
              </el-button>
            </span>
          </el-dialog>
          <div class="word-name" v-if="reportName">
            <svg class="icon link-img" aria-hidden="true" v-if="reportName">
              <use href="#icon-link"></use>
            </svg>
            <span>{{ reportName }}</span>
            <i class="el-icon-close" @click="clearFormat"></i>
          </div>
        </div>
        <!-- 收件人 -->
        <div class="addressee">
          <div class="addressee-title">
            <!-- 收件人 -->
            {{ $t("component_sendmail_label_touser") }}
          </div>
          <div class="send">
            <el-radio-group v-model="addresseeToSomebody">
              <el-radio label="me">
                <!-- 只发送给我 -->
                {{ $t("label.reportsSubscriptionPage.email.tome") }}
              </el-radio>
              <el-radio label="meandothers" v-if="toSendOters">
                <!-- 发送给我和其他用户  v-if="toSendOters" -->
                {{ $t("label.reportsSubscriptionPage.email.toothers") }}
              </el-radio>
            </el-radio-group>
          </div>

          <div
            class="addresseeSearch"
            v-if="addresseeToSomebody == 'meandothers' && toSendOters"
          >
            <div class="search">
              <!-- 搜索 -->
              {{ $t("label.quickbooks.search") }} :
            </div>
            <el-select
              popper-class="addresseeSelect"
              v-model="addresseevValue"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              @change="switchRoles"
            >
              <el-option
                v-for="item in addresseeTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!--可用值,所选成员  -->
          <el-transfer
            v-if="addresseeToSomebody == 'meandothers' && toSendOters"
            :titles="[
              $t('label.recordtype.edit.unselected'),
              $t('label.multipleactivity.memberselected'),
            ]"
            filterable
            v-model="personChoose"
            :data="personList"
            @change="changepersonChoose"
          ></el-transfer>
        </div>
        <!-- 报表运行方式:默认：我的，其他人员：以其他人的账号来运行此报表是什么效果 -->
      </div>
      <div class="report">
        <div class="report-title">
          <!-- 表运行方式 -->
          {{ $t("vue_label_report_operationmode") }}
        </div>
        <div class="report-move">
          <el-radio-group v-model="reportMove">
            <el-radio label="reportToMe">
              {{ $t("vue_label_report_me") }}</el-radio
            >
            <el-radio label="reportToSome">
              <!-- 其他人员  -->
              {{ $t("vue_label_report_personothers") }}
            </el-radio>
          </el-radio-group>
          <!-- 查找/查找多选字段 -->
          <el-select
            v-if="reportMove == 'reportToSome'"
            clearable
            v-model="runningasId"
            class="no-suffix"
          >
            <p class="searchTipBox">
              <span
                @click="remoteSearchOwner"
                style="display: inline-block; width: 100%"
              >
                <i class="el-icon-search"></i>
                <span style="padding-left: 10px">
                  <!-- 前往精准搜索 -->
                  {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                </span>
              </span>
            </p>
            <el-option
              v-show="runningasName !== ''"
              :key="runningasName"
              :label="runningasName"
              :value="runningasId"
            >
            </el-option>
          </el-select>
          <div>
            <el-dialog
              :title="$t('label.searchs')"
              custom-class="dialog-form-wrapper"
              width="60%"
              top="5%"
              style="height: 91%"
              :visible.sync="showSearchTable"
              :lock-scroll="true"
              :close-on-click-modal="false"
              :modal="true"
              append-to-body
            >
              <search-table
                ref="searchTable"
                :fieldId="fieldId"
                :checked="checked"
                :relevant-objid="relevantObjId"
                :relevant-prefix="relevantPrefix"
                @changeSelect="changeSelectOwner"
              />
            </el-dialog>
          </div>
        </div>
      </div>
      <!-- 条件 汇总报表 矩阵式报表 $t('label.summaryreport')  $t('label.matrixreport')-->
      <div
        class="condition"
        v-if="
          reportSubscribeType == $t('label.summaryreport') ||
          reportSubscribeType == $t('label.matrixreport')
        "
      >
        <div class="title">
          <!-- 条件 -->
          {{ $t("label.assignment.setup.detail.conditon") }}
        </div>
        <div class="desc">
          {{ $t("vue_label_report_conditiondesc_one") }}
          <!-- 除了订阅外，您可为此报表设置条件。您将在满足条件时获得通知。这是可选项。 -->
        </div>
        <el-checkbox v-model="conditionchecked">
          {{ $t("vue_label_report_conditiondesc_two") }}
          <!-- 将条件添加到此报表 -->
        </el-checkbox>
        <div v-if="conditionchecked">
          <div class="desc-detail">
            <!-- 您将在满足所有条件时获得通知  -->
            {{ $t("vue_label_report_conditiondesc_three") }}
            <span class="font-bolder">(AND)</span>
          </div>
          <div
            class="condition-detail"
            v-for="(conitem, index) in coditionList"
            :key="index"
          >
            <div class="seat" v-show="index == '0'"></div>
            <div class="seatAnd" v-show="index != '0'">AND</div>
            <div>
              <div>
                <span class="start">*</span>
                {{ $t("label.reportsSubscriptionPage.condition.aggregate") }}
              </div>
              <!-- 例如金额等字段需要统计类型，后端返回带统计类型的是labelName，无统计类型的为labelname -->
              <el-select
                v-model="conitem.field"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
              >
                <el-option
                  v-for="item in fieldList"
                  :key="item.id"
                  :label="item.labelName ? item.labelName : item.labelname"
                  :value="item.id"
                  :disabled="
                    coditionList.some((disitem) => disitem.field === item.id)
                  "
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <div>
                <span class="start">*</span>
                <!-- 逻辑符 -->
                {{ $t("label.reportsSubscriptionPage.condition.operator") }}
              </div>
              <el-select
                v-model="conitem.operator"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
              >
                <el-option
                  v-for="operatoritem in operatorList"
                  :key="operatoritem.value"
                  :label="operatoritem.label"
                  :value="operatoritem.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <div>
                <span class="start">*</span>
                {{ $t("label.campaign.channel.value") }}
                <!-- 值 -->
              </div>
              <el-input v-model="conitem.value"></el-input>
            </div>
            <!-- 删除按钮 -->
            <div v-if="coditionList.length > 1">
              <el-button
                icon="el-icon-delete"
                @click="deleteCondition(index)"
                class="deleteBtn"
              ></el-button>
            </div>
          </div>
          <el-button class="add-condi" @click="addCondition">
            + {{ $t("label_tabpage_addcondition") }}
            <!-- 添加条件 汇总+报表  -->
          </el-button>
          <!-- <div class="email">
            <div class="email-title">电子邮件选项</div>
            <div class="email-radio">
              <el-radio v-model="emailMove" label="SummaryAndReport">
                {{ $t("label.sso.summary") }}+{{ $t("label.visualpage.btype") }}
              </el-radio>
              <el-radio v-model="emailMove" label="SummaryOnly">
                仅汇总
              </el-radio>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 导出弹框 -->
      <export-report
        :exportReportTo="exportReportTo"
        :reportId="reportId"
        :totalPages="totalPages"
        :totalSize="totalSize"
        @exportBoxCancels="exportBoxCancels"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="reportSubscribeCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="saveReportSubscribe">
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import exportReport from "./exportReport";
import { saveReportSubscribe, gotoSubscribe } from "../api";
import * as CommonObjApi from "../api";

export default {
  components: {
    exportReport,
  },
  props: [
    "reportId",
    "reportSubscribeTo",
    "reportSubscribeType",
    "isreporEdit",
  ],
  data() {
    return {
      allgroups: [], //小组
      allroles: [], //角色
      allusers: [], //用户
      loading: false, //
      fieldList: [], //报表可操作的统计值
      showSearchTable: false, //搜素弹框显示隐藏
      ownerName: "", //以某个用户视角运行报表默认 ：当前用户name
      ownerId: "", //以某个用户视角运行报表默认： 当前用户id
      fieldId: "", //搜索其他用户组件的参数
      checked: "", //搜索其他用户组件的参数
      relevantObjId: "", //固定类型user
      relevantPrefix: "", //固定编号005
      // 保存筛选字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
          objid: "",
        },
      ],
      reportName: "", //格式化报表 or 仅限详情信息
      toSendOters: false, //通过传送报表id判断，小组，用户角色，是否有数据，无数据则不显示发送给其他人按钮，
      frequency: "", //频率：每天，每周，每月
      checkboxweek: [], //每周显示选中星期几
      chooseformatActive: "", //附加文件选中格式化or详情
      exportReportTo: false, //导出弹框
      totalSize: 0, // 报表总条数
      dayOrWeek: "", //在每月第几天执行或者在每月第几个星期执行 ，day或week
      totalPages: "",
      startEndDate: [], //开始结束日期
      runOtherPerson: "", //报表运行方式：搜索其他人员
      coditionList: [
        {
          field: "",
          operator: "",
          value: "",
        },
      ], //条件遍历
      specialDayList: [
        {
          value: "choosespecialday",
          label: this.$i18n.t("date"), //日期
        },
        {
          value: "chooselastday",
          label: this.$i18n.t("label.schedulejob.setup.schedule.lastday"), //最后一天
        },
      ], //频率-每月-时间：特定-天
      chooseSpecialSomeDay: "", //频率-每月-时间：特定-天：日期-选中的日期
      specialDay: "", //频率-每月-时间：特定-天：日期or最后一天
      monthTimeList: [
        {
          value: "relative",
          label: this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.time.relative"
          ),
        },
        {
          value: "given",
          label: this.$i18n.t("vue_label_report_given"), //"特定",
        },
      ], // 频率-每月-时间
      monthWeekList: [
        {
          label: this.$i18n.t("label.schedulejob.setup.schedule.st1"), // "第一个",
          value: this.$i18n.t("label.schedulejob.setup.schedule.st1"),
        },
        {
          label: this.$i18n.t("label.schedulejob.setup.schedule.st2"), // "第二个",
          value: this.$i18n.t("label.schedulejob.setup.schedule.st2"), // "第二个",
        },
        {
          label: this.$i18n.t("label.schedulejob.setup.schedule.st3"), // "第三个",
          value: this.$i18n.t("label.schedulejob.setup.schedule.st3"), // "第三个",
        },
        {
          label: this.$i18n.t("label.schedulejob.setup.schedule.st4"), // "第四个",
          value: this.$i18n.t("label.schedulejob.setup.schedule.st4"), // "第四个",
        },
      ], // 频率-每月-时间：相对-星期
      monthDayList: [
        {
          value: "sunday",
          label: this.$i18n.t("sunday"), //"星期日",//
        },
        {
          value: "monday",
          label: this.$i18n.t("monday"), // "星期一",
        },
        {
          value: "tuesday",
          label: this.$i18n.t("tuesday"), // "星期二",
        },
        {
          value: "wednesday",
          label: this.$i18n.t("wednesday"), //"星期三",
        },
        {
          value: "thursday",
          label: this.$i18n.t("thursday"), //"星期四",
        },
        {
          value: "friday",
          label: this.$i18n.t("friday"), // "星期五",
        },
        {
          value: "saturday",
          label: this.$i18n.t("saturday"), //"星期六",
        },
      ], // 频率-每月-时间：相对-天
      monthTime: "", //每月-时间
      monthWeek: "", //每月-星期
      monthDay: "", //每月-天
      universalTime: "", //通用时间
      addresseeToSomebody: "me", //收件人发送给我or其他人
      subscribeId: "", //订阅id
      scheduleId: "", //定时任务id
      schedulename: "", //定时任务名称
      runningasId: "", //以谁的角度运行报表id
      runningasName: "", //以谁的角度运行报表名字
      weekList: [
        {
          value: this.$i18n.t("sunday"),
          id: "Sun",
        },
        {
          value: this.$i18n.t("monday"),
          id: "Mon",
        },
        {
          value: this.$i18n.t("tuesday"),
          id: "Tues",
        },
        {
          value: this.$i18n.t("wednesday"),
          id: "Wed",
        },
        {
          value: this.$i18n.t("thursday"),
          id: "Thur",
        },
        {
          value: this.$i18n.t("friday"),
          id: "Fri",
        },
        {
          value: this.$i18n.t("saturday"),
          id: "Sat",
        },
      ], //频率-每周-周几
      addresseeTypeList: [
        {
          value: "user",
          label: this.$i18n.t("label.document.history.user"), // "用户",
        },
        {
          value: "roles",
          label: this.$i18n.t("label_tabpage_rolez"), //"角色",
        },
        // {
        //   value: "roleandsub",
        //   label: this.$i18n.t("label.report.share.roleandsub"), //"角色及内部下属",
        // },
        {
          value: "publicgroup",
          label: this.$i18n.t("label.publicgroup"), //"公用小组",
        },
        // {
        //   value: "partneruser",
        //   label: this.$i18n.t("label.partneruser"), //"合作伙伴用户",
        // },
        // {
        //   value: "roleofpartner",
        //   label: this.$i18n.t("label.roleofpartner"), //"合作伙伴角色",
        // },
      ], //收件人类型
      addresseevValue: "user", //选中收件人类型
      personList: [], //收件人列表
      personChoose: [], //选中的收件人
      reportMove: "reportToMe", // 报表运行人员
      conditionchecked: false, //添加条件复选框
      choosePolymerization: "Recordcount", //选中聚合
      changepersonChooseData: [], //收件人id添加前缀
      excelValue: "excel",
      exceloptions: [
        {
          //附加文件-格式化
          value: "excel",
          label: "Excel " + this.$i18n.t("label.ems.format") + ".xlsx", //
        },
      ],
      polymerizationList: [
        {
          value: "Recordcount",
          label: this.$i18n.t("vue_label_record_count"), //"记录计数",
        },
      ], //聚合列表
      chooseOperator: "equal", //选中运算符
      operatorList: [
        // e等于，g大于，l小于，h大于或等于，m小于或等于，n不等于
        {
          //运算符列表
          value: "e",
          label: this.$i18n.t("label_tabpage_equalz"), //"等于",
        },
        {
          //运算符列表
          value: "n",
          label: this.$i18n.t("noequal"), //"不等于",
        },
        {
          //运算符列表
          value: "g",
          label: this.$i18n.t("label_tabpage_greaterz"), //"大于",
        },
        {
          //运算符列表
          value: "l",
          label: this.$i18n.t("less"), //"小于",
        },
        {
          //运算符列表
          value: "h",
          label: this.$i18n.t("noless"), //"大于或等于",
        },
        {
          //运算符列表
          value: "m",
          label: this.$i18n.t("nogreate"), //"小于或等于",
        },
      ], // 运算符列表
      EqualValue: "", //条件-值
      emailMove: "SummaryAndReport", //条件-邮件：汇总+报表or仅汇总
      dialogVisible: false, //附加文件弹框显示隐藏
    };
  },

  computed: {
    fieldListDisabled() {
      return (id) => {
        this.coditionList.map((item) => {
          if (id == item.field) {
            return true;
          } else {
            return false;
          }
        });
      };
    },
  },
  methods: {
    // 增减收件人时，触发事件
    changepersonChoose(value, direction, array) {
      // value：当前选中值数组，direction：移动的方向，array：移动值数组
      // 选中时，把选择的id添加前缀，用户：user-,角色：role-，小组：group-;
      let chooseId = "";
      if (this.addresseevValue == "user") {
        chooseId = "user-";
      } else if (this.addresseevValue == "roles") {
        chooseId = "role-";
      } else if (this.addresseevValue == "publicgroup") {
        chooseId = "group-";
      }
      // right选中收件人，left去除收件人
      if (direction == "right") {
        if (array.length > 1) {
          array.map((item) => {
            this.changepersonChooseData.push(chooseId + item);
          });
        } else {
          this.changepersonChooseData.push(chooseId + array);
        }
      } else {
        array.map((item) => {
          this.changepersonChooseData.map((item2, index2) => {
            if (item2.indexOf(item) > -1) {
              this.changepersonChooseData.splice(index2, 1);
            }
          });
        });
      }
    },
    // 开始结束日期在两个月以上
    dateTwoMonths() {
      if (this.startEndDate) {
        let startTime = new Date(this.startEndDate[0].replace(/-/g, "/"));
        let endTime = new Date(this.startEndDate[1].replace(/-/g, "/"));
        let month1 = startTime.getMonth() + 1;
        let month2 = endTime.getMonth() + 1;
        let year1 = startTime.getFullYear();
        let year2 = endTime.getFullYear();
        if (year1 == year2 && month2 > month1) {
          return true;
        } else if (year1 < year2) {
          return true;
        } else {
          this.$message.error(
            "开始结束日期不满足订阅频率"
            // this.$i18n.t("label.reportsSubscription.time.meets.subscription.frequency")
          );
          return false;
        }
      } else {
        this.$message.error(
          this.$i18n.t("label.reportsSubscription.select.start.endtime")
        ); //"请选择开始时间结束时间!")
      }
    },
    // 特定-日期-默认为1
    chooseDate(value) {
      if (value == "choosespecialday") {
        this.chooseSpecialSomeDay = 1;
      }
    },
    // 清除格式化
    clearFormat() {
      this.chooseformatActive = "";
      this.reportName = "";
    },
    // 获取当前用户信息
    async getUserName() {
      let res = await CommonObjApi.getUserInfo();
      this.ownerId = res.data.userId;
      this.ownerName = res.data.userName;
      this.runningasName = res.data.userName;
      this.runningasId = res.data.userId;
      var myDate = new Date();
      this.universalTime = myDate.getHours() + ":00"; //获取当前小时数(0-23)
    },

    // 查找所有人
    remoteSearchOwner() {
      // 查找所有人数据写死
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      this.runningasName = row.data && row.data.name ? row.data.name : "";
      this.runningasId = row.data && row.data.id ? row.data.id : "";
      this.showSearchTable = false;
    },
    // 切换用户类型：角色，用户，小组
    switchRoles(value) {
      if (value == "roles") {
        this.personList = this.allroles;
      } else if (value == "user") {
        this.personList = this.allusers;
      } else if (value == "publicgroup") {
        this.personList = this.allgroups;
      }
    },
    // 转换时间戳
    timeToDate(time) {
      let date = new Date(time);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + 1 < 10 ? "0" + date.getDate() : date.getDate();
      let dateTime = Y + M + D;
      return dateTime;
    },
    //查询报表订阅
    async gotoSubscribe(reportid) {
      let params = {
        reportid: reportid,
      };
      let res = await gotoSubscribe(params);
      this.schedulename = res.data.schedulename;
      //判断有没有统计字段，固定字段：统计值
      if (res.data.fieldList) {
        this.fieldList = res.data.fieldList;
      } else {
        this.fieldList = [];
      }
      this.fieldList.push({
        id: "totalrecord",
        labelName: this.$i18n.t("label.recordamount"), //固定的记录数
      });
      if (res.data.allgroups || res.data.allroles || res.data.allusers) {
        // 小组数据
        let groups = JSON.parse(res.data.allgroups);
        if (groups && groups.length > 0) {
          groups.map((item) => {
            this.allgroups.push({
              key: item.id,
              label: `${this.$i18n.t("label.publicgroup")}:${item.name}`, //公用小组
            });
          });
        }
        // 角色数据
        let roles = JSON.parse(res.data.allroles);
        if (roles && roles.length > 0) {
          roles.map((item) => {
            this.allroles.push({
              key: item.roleid,
              label: `${this.$i18n.t("label.tabpage.rolez")}: ${item.rolename}`,
            });
          });
        }
        // 用户数据
        let users = JSON.parse(res.data.allusers);
        if (users && users.length > 0) {
          users.map((item) => {
            this.allusers.push({
              key: item.id,
              label: `${this.$i18n.t("label.emailsync.status.user")}:  ${
                item.name
              }`,
            });
          });
        }
        if (
          this.allgroups.length > 0 ||
          this.allroles.length > 0 ||
          this.allusers.length > 0
        ) {
          this.toSendOters = true;
          this.personList = [];
          this.personList = this.allusers; //默认为用户列表
        }
      }

      //编辑订阅
      if (this.isreporEdit == "edit") {
        // 条件数据
        this.frequency = res.data.frequency;
        if (res.data.allconditions && res.data.allconditions.length > 0) {
          this.conditionchecked = true;
          let arr = [];
          let obj;
          res.data.allconditions.map((item) => {
            if (item.fieldId) {
              obj = {
                field: item.fieldId,
                operator: item.operator,
                value: item.value,
              };
              arr.push(obj);
            }
          });
          this.coditionList = arr;
        } else {
          this.conditionchecked = false;
          this.coditionList = [
            {
              field: "",
              operator: "",
              value: "",
            },
          ];
        }
        // weekly或者monthly
        if (res.data.frequency == "monthly") {
          // 根据第几个星期来判断是相对
          if (res.data.weeknum) {
            this.monthTime = "relative";
            this.monthWeek = res.data.weeknum;
            this.monthDay = res.data.mweek;
          } else if (res.data.days == "last") {
            // 特定，最后一天
            this.monthTime = "given";
            this.specialDay = "chooselastday";
          } else if (res.data.days && res.data.days != "last") {
            // 特定，日期：某天
            this.monthTime = "given";
            this.specialDay = "choosespecialday";
            this.chooseSpecialSomeDay = res.data.days;
          }
        } else if (res.data.frequency == "weekly") {
          let weeks = res.data.weeks.split(",");
          this.checkboxweek = [];
          weeks.map((item) => {
            if (item == "Mon") {
              this.checkboxweek.push(this.$i18n.t("monday"));
            } else if (item == "Tues") {
              this.checkboxweek.push(this.$i18n.t("tuesday"));
            } else if (item == "Wed") {
              this.checkboxweek.push(this.$i18n.t("wednesday"));
            } else if (item == "Thur") {
              this.checkboxweek.push(this.$i18n.t("thursday"));
            } else if (item == "Fri") {
              this.checkboxweek.push(this.$i18n.t("friday"));
            } else if (item == "Sat") {
              this.checkboxweek.push(this.$i18n.t("saturday"));
            } else if (item == "Sun") {
              this.checkboxweek.push(this.$i18n.t("sunday"));
            }
          });
          this.monthTime = "relative";
          this.monthDay = "monday";
          this.monthWeek = this.$i18n.t("label.schedulejob.setup.schedule.st1");
        }
        // 运行角度和当前登陆人不一致
        if (res.data.runningas != this.ownerId) {
          this.reportMove = "reportToSome";
          this.runningasId = res.data.runningas;
          this.runningasName = res.data.runningasccname;
        }
        // 发送人员和当前登陆人不一致
        if (res.data.sendtousers && res.data.sendtousers != this.ownerName) {
          this.addresseeToSomebody == "meandothers";
          this.toSendOters = true;
          this.addresseevValue = "user";
          this.personChoose = [];
          // this.personChoose = res.data.sendtousers.split(",");
          // 去除发件人id前缀
          let sendtousersData = res.data.sendtousers.split(",");
          sendtousersData.map((item) => {
            if (item.indexOf("user-") > -1) {
              item = item.substring(5);
            } else if (item.indexOf("role-") > -1) {
              item = item.substring(5);
            } else if (item.indexOf("group-") > -1) {
              item = item.substring(6);
            }
            this.personChoose.push(item);
          });
          this.changepersonChooseData = res.data.sendtousers.split(",");
        }
        this.scheduleId = res.data.scheduleId;
        this.subscribeId = res.data.id;
        this.addresseeToSomebody = res.data.sendtotype;
        this.emailMove = res.data.emailoptions;
        this.frequency = res.data.frequency;
        this.universalTime = res.data.executetime + ":00";
        this.startEndDate.push(this.timeToDate(res.data.start));
        this.startEndDate.push(this.timeToDate(res.data.end));
        this.chooseformatActive = res.data.attachment;
        if (this.chooseformatActive == "format") {
          this.reportName = this.$i18n.t(
            "label.reportsSubscription.format.report"
          ); //格式化报表 (Excel 格式 .xlsx)
        } else if (this.chooseformatActive == "detail") {
          this.reportName = this.$i18n.t(
            "label.reportsSubscription.details.only"
          );
          //"仅限详细信息 (Excel 格式 .xlsx)";
        }
      }
    },
    // 关闭导出弹框
    exportBoxCancels() {
      this.exportReportTo = false;
    },

    // 添加条件
    addCondition() {
      //每个统计值只能使用一次，条件数量不可超过统计值字段数量
      if (this.coditionList.length < this.fieldList.length) {
        this.coditionList.push({
          field: "",
          operator: "",
          value: "",
        });
      }
    },
    // 删除条件
    deleteCondition(index) {
      this.coditionList.splice(index, 1);
    },
    // 附件文件弹框显示
    addFileFrameShow() {
      this.dialogVisible = true;
      // this.exportReportTo = true;
    },
    // 附加文件-仅限详细信息
    chooseDetailActivefn() {
      this.chooseformatActive = "detail";
    },
    //附加文件- 格式化报表
    chooseformatActivefn() {
      this.chooseformatActive = "format";
    },
    // 附件文件弹框取消关闭
    handleClose() {
      this.chooseformatActive = ""; //清空选中格式
      this.dialogVisible = false;
    },
    // 附件文件弹框确定关闭
    handleConfirm() {
      this.dialogVisible = false;
      if (this.chooseformatActive == "format") {
        this.reportName = this.$i18n.t(
          "label.reportsSubscription.format.report"
        ); //格式化报表 (Excel 格式 .xlsx)
      } else if (this.chooseformatActive == "detail") {
        this.reportName = this.$i18n.t(
          "label.reportsSubscription.details.only"
        );
        //"仅限详细信息 (Excel 格式 .xlsx)";
      }
    },
    // 星期转换
    weekToDays(data) {
      let weeks = [];
      data.map((item) => {
        if (item == this.$i18n.t("monday")) {
          weeks.push("Mon");
        } else if (item == this.$i18n.t("tuesday")) {
          weeks.push("Tues");
        } else if (item == this.$i18n.t("wednesday")) {
          weeks.push("Wed");
        } else if (item == this.$i18n.t("thursday")) {
          weeks.push("Thur");
        } else if (item == this.$i18n.t("friday")) {
          weeks.push("Fri");
        } else if (item == this.$i18n.t("saturday")) {
          weeks.push("Sat");
        } else if (item == this.$i18n.t("sunday")) {
          weeks.push("Sun");
        }
      });
      return weeks.join(",");
    },
    //判断两个日期相差的天数
    dateMinus(dateStart, dateEnd) {
      var sdate = new Date(dateStart);
      var now = new Date(dateEnd);
      var days = now.getTime() - sdate.getTime();
      var day = Math.floor(days / (1000 * 60 * 60 * 24));
      return day;
    },
    // 保存报表订阅
    async saveReportSubscribe() {
      // 频率：每周：把星期几转换成字符串
      //每月： 根据相对还是特定判断在每月第几天执行或者在每月第几个星期执行 ，day或week
      //频率：每周
      let weeks;
      if (this.frequency == "weekly") {
        this.monthTime = "";
        this.monthDay = "";
        this.monthWeek = "";
        this.specialDay = "";
        this.chooseSpecialSomeDay = "";
        this.dayOrWeek = "day";
        weeks = this.weekToDays(this.checkboxweek);
        if (!weeks) {
          this.$message.error(
            // "请选择订阅频率!"
            this.$i18n.t(
              "label.reportsSubscription.select.subscription.frequency"
            )
          );
          return;
        } else if (
          this.dateMinus(this.startEndDate[0], this.startEndDate[1]) < 7
        ) {
          this.$message.error(
            this.$i18n.t(
              "label.reportsSubscription.time.meets.subscription.frequency"
            )
          );
          //"开始结束日期不满足订阅频率!"
          return;
        }
      } else {
        //每月：相对
        if (
          this.monthTime == "relative" &&
          this.dateMinus(this.startEndDate[0], this.startEndDate[1]) >= 30
        ) {
          this.dayOrWeek = "week";
          this.specialDay = ""; //日期or最后一天
          this.chooseSpecialSomeDay = ""; //日期中的某一天
        } else if (this.monthTime == "given") {
          //：特定
          this.dayOrWeek = "day";
          this.monthWeek = ""; //第几个星期
          this.monthDay = ""; //星期几
          if (this.specialDay == "chooselastday") {
            // 特定，最后一天，选择时间范围必须在两个月之内，否则保存不成功
            if (this.dateTwoMonths()) {
              this.chooseSpecialSomeDay = "last"; //最后一天
            } else {
              return;
            }
          } else if (
            this.specialDay == "choosespecialday" &&
            this.dateMinus(this.startEndDate[0], this.startEndDate[1]) >= 30
          ) {
            //每月：某一天,时间范围两个月以上
            if (this.dateTwoMonths()) {
              this.chooseSpecialSomeDay = this.chooseSpecialSomeDay.toString();
            } else {
              return;
            }
          } else {
            this.$message.error(
              this.$i18n.t(
                "label.reportsSubscription.time.meets.subscription.frequency"
              )
            );
            return;
          }
        } else {
          this.$message.error(
            this.$i18n.t(
              "label.reportsSubscription.time.meets.subscription.frequency"
            )
          );
          return;
        }
      }
      let sendtousers;
      if (this.addresseeToSomebody == "meandothers") {
        sendtousers = this.changepersonChooseData.toString(); //发送给其他人
      } else {
        sendtousers = this.ownerId; //发送给本人
      }
      // 没有选中条件，条件为空
      if (!this.conditionchecked) {
        this.coditionList = [];
      }
      //时间仅显示前两位
      let executetime = "";
      if (this.universalTime) {
        let indexs = this.universalTime.indexOf(":");
        executetime = this.universalTime.substring(0, indexs);
      } else {
        this.$message.error("请选择时间！");
        return;
      }

      if (this.startEndDate[0] && this.startEndDate[1]) {
        // 附件文件为必选
        if (this.chooseformatActive) {
          // 附加文件参数：chooseformatActive，格式化或者是详情信息，接口暂无此参数
          let params = {
            id: this.isreporEdit == "edit" ? this.subscribeId : "", //订阅id,新建时可以不填，编辑时必填
            reportid: this.reportId, //报表id
            runningas_lkid: this.runningasId, //以某个用户视角运行报表,如超级管理员
            sendtotype: this.addresseeToSomebody, //发送类型
            attachment: this.chooseformatActive, //报表附件
            sendtousers: sendtousers, //发送给的人
            emailformat: "general", //报表形式,general或detailed，这版本不包括这个功能，默认general
            emailoptions: this.emailMove, //邮件选项 SummaryOnly或SummaryAndReport
            scheduleId: this.isreporEdit == "edit" ? this.scheduleId : "", //定时任务的id
            schedulename: this.schedulename, //定时任务名称
            startdate: this.startEndDate[0], //开始时间
            enddate: this.startEndDate[1], //结束时间
            frequency: this.frequency, //频率，weekly或者monthly
            days: this.chooseSpecialSomeDay, //在每月第几日，1，2…30,31,最后
            executetime: executetime, //执行时间，如18this.universalTime
            weeknum: this.monthWeek, //第几个星期,如 第一个，第二个，第三个，第四个
            weeks: weeks, //在每周星期几执行,Mon,Tues,Wed,Thur,Fri,Sat,Sun
            monthtype: this.dayOrWeek, //在每月第几天执行或者在每月第几个星期执行 ，day或week
            mweek: this.monthDay, //在每个月的某个星期的周几，如 星期一，星期二，星期三…星期日
            conditions: JSON.stringify(this.coditionList), //条件
          };
          let res = await saveReportSubscribe(params);
          if (res.result) {
            if (this.isreporEdit == "edit") {
              this.$message.success(
                this.$i18n.t("label.reportsSubscription.edited.successfully")
              ); //报表订阅编辑成功！
            } else {
              this.$message.success(
                this.$i18n.t("label.reportsSubscription.succeeded")
              ); //报表订阅成功！
            }
            this.reportSubscribeCancel();
            this.$emit("reportSubscribeConfirm");
          }
        } else {
          this.$message.error(
            this.$i18n.t("label.reportsSubscription.select.attached.file")
          ); //请选择附加文件的类型
        }
      } else {
        this.$message.error(
          this.$i18n.t("label.reportsSubscription.select.start.endtime")
        ); //"请选择开始时间结束时间!")
      }
    },
    // 弹框取消按钮
    reportSubscribeCancel() {
      this.$emit("reportSubscribeCancel");
      this.reportName = "";
      this.frequency = "";
      this.chooseSpecialSomeDay = "";
      this.checkboxweek = [];
      this.monthTime = "";
      this.monthWeek = "";
      this.monthDay = "";
      this.addresseeToSomebody = "me";
      this.reportMove = "reportToMe";
      this.specialDay = "";
      this.chooseformatActive = "";
      this.ownerId = "";
      this.ownerName = "";
      this.personChoose = []; //清空收件人列表
      this.personList = []; //清空选中收件人列表
      this.startEndDate = []; //清空时间
      this.fieldList = [];
      this.conditionchecked = false;
      this.allgroups = [];
      this.allroles = [];
      this.allusers = [];
      this.coditionList = [
        {
          field: "",
          operator: "",
          value: "",
        },
      ];
    },

    //每月-时间:相对or特定更改
    changeMonthTime() {
      if (this.monthTime == "relative") {
        this.monthWeek = this.$i18n.t("label.schedulejob.setup.schedule.st1");
        this.monthDay = "monday";
      } else if (this.monthTime == "given") {
        this.specialDay = "chooselastday";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-input.is-disabled .el-input__icon {
  height: 30px;
  line-height: 30px;
}
.report-order ::v-deep .el-dialog__body {
  height: 600px;
}
.addfile ::v-deep .el-dialog__body {
  height: 350px;

  .view {
    font-size: 16px;
    font-weight: bolder;
  }
  .excel {
    margin-top: 16px;
  }
  .triangle {
    width: 30px;
    height: 30px;
    border-top: 30px solid #006dcc;
    border-left: 30px solid transparent;
    margin-left: 142px;
    position: absolute;
    margin-top: -1px;
  }
  .check-sign {
    width: 6px;
    height: 12px;
    border-color: #fff;
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: -27px;
    left: -11px;
    position: absolute;
  }
  .choose-desc {
    display: flex;
    .desc-border {
      border: 1px solid #dddbda;
      box-shadow: 0 1px 2px #eae8e8;
      border-radius: 5px;
      text-align: center;
      width: 192px;
      height: 176px;
      margin-right: 30px;
      padding: 0 16px;
    }
    .desc-title {
      font-size: 16px;
      margin: 40px 0 16px;
    }
    .desc-border:hover {
      border: 2px solid #006dcc;
    }

    .choose-active {
      border-radius: 5px;
      border: 2px solid #006dcc;
      text-align: center;
      width: 192px;
      height: 175px;
      margin-right: 30px;
      padding: 0 16px;
    }
  }
}
::v-deep .el-dialog__header .el-dialog__title {
  font-size: 16px;
  font-weight: normal;
}
::v-deep .el-radio-button__inner {
  padding: 0 10px;
  line-height: 28px;
  font-size: 13px;
  color: #005397;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #005397;
  border-color: #005397;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #005397;
  border-color: #005397;
}
::v-deep .el-checkbox-button--small .el-checkbox-button__inner {
  padding: 9px 16px;
}
::v-deep .el-radio-group {
  margin-bottom: 0 !important;
}
.title-set {
  font-size: 16px;
  margin: 8px 0 16px 0;
}
.time-detail,
.condition-detail {
  display: flex;
  margin-top: 16px;
  .div_content {
    display: flex;
  }
  .seat {
    width: 30px;
  }
  .seatAnd {
    height: 30px;
    font-weight: bolder;
    margin-top: 18px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  ::v-deep .el-input__inner {
    line-height: 30px;
    height: 30px;
    width: 240px;
  }
  ::v-deep .el-select {
    margin-right: 20px;
  }
}
.condition-detail {
  margin: 16px 0 0 0px;
  ::v-deep .el-input__inner {
    width: 220px;
  }
}
.email {
  .email-title {
    margin: 10px 0 0px;
  }
  .email-radio {
    display: flex;
    flex-direction: column;
    ::v-deep .el-radio {
      margin-top: 5px;
    }
    ::v-deep .el-radio__label {
      font-size: 12px;
    }
  }
}

.universal {
  margin-top: 12px;
  display: flex;
  .startend {
    ::v-deep .el-date-editor .el-range-separator {
      padding: 0 15px;
      height: 22px;
      line-height: 22px;
    }
    ::v-deep .el-range-editor .el-range-input {
      font-size: 12px;
    }
    ::v-deep .el-input__icon {
      line-height: 30px;
      height: 30px;
    }
    ::v-deep .el-input__inner {
      line-height: 30px;
      height: 30px;
      width: 240px;
      font-size: 12px;
    }

    margin-right: 23px;
  }
  .time {
    ::v-deep .el-input__icon {
      line-height: 30px;
    }
    ::v-deep .el-input__inner {
      line-height: 30px;
      height: 30px;
      width: 240px;
      font-size: 12px;
    }
  }
}
.enclosure {
  margin-top: 12px;
  ::v-deep .el-button {
    padding: 8px 15px;
  }
  .helptext {
    width: 14px;
    height: 14px;
    display: inline-block;
  }
  .word-name {
    margin-top: 5px;
    display: flex;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    .link-img {
      width: 12px;
      margin-right: 5px;
    }
    i {
      margin-left: 5px;
      padding-top: 2px;
    }
  }
}
.addressee {
  margin-top: 12px;
  ::v-deep .el-radio-group {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
  }
  .addressee-title {
    font-size: 16px;
  }
  .send {
    display: flex;
    flex-direction: column;
    ::v-deep .el-radio {
      margin-top: 5px;
      font-size: 12px;
    }
  }
  ::v-deep .el-radio__label {
    font-size: 12px;
  }
  .search {
    width: 50px;
    height: 30px;
    line-height: 30px;
  }
  .addresseeSearch {
    margin-top: 12px;
    display: flex;
    ::v-deep .el-checkbox__label {
      font-size: 12px;
    }

    ::v-deep .el-input__icon {
      line-height: 30px;
    }
    ::v-deep .el-input__inner {
      line-height: 30px;
      height: 30px;
      width: 150px;
    }
    ::v-deep .el-input {
      width: auto;
      margin-right: 20px;
    }
    ::v-deep .el-button {
      padding: 4px 16px;
    }
  }
}
// 穿梭框

::v-deep .el-transfer-panel.el-transfer-panel__header {
  line-height: 40px;
  height: 40px;
}
::v-deep .el-transfer {
  margin-top: 12px;
  .el-button {
    padding: 8px 10px;
  }
}
::v-deep .el-transfer-panel .el-transfer-panel__header .el-checkbox__label {
  font-size: 12px;
  height: 40px;
  line-height: 40px;
}
::v-deep .el-checkbox .el-checkbox__label {
  font-size: 12px !important;
}
::v-deep .el-transfer-panel .el-transfer-panel__header {
  line-height: 40px;
  height: 40px;
}
::v-deep .el-transfer-panel__list.is-filterable {
  height: 203px;
}
::v-deep .el-transfer-panel__filter .el-input__inner {
  height: 31px;
}
.report {
  margin-top: 12px;
  .report-move {
    display: flex;
    flex-direction: column;
    ::v-deep .el-input {
      width: 240px;
    }
  }
  ::v-deep .el-radio-group {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
  }

  // .report-title {
  //   margin: 8px 0;
  // }
  ::v-deep .el-radio {
    margin-top: 5px;
  }
  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}
.no-suffix {
  width: 100%;
  margin-top: 10px;
  ::v-deep .el-input {
    width: 100% !important;
  }
  ::v-deep .el-input__icon {
    height: 30px !important;
    line-height: 30px !important;
  }
  ::v-deep .el-input__inner {
    height: 30px !important;
  }
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
}
.start {
  color: red;
}
.condition {
  margin-top: 12px;
  .deleteBtn {
    padding: 6px 10px !important ;
    margin-top: 20px !important ;
  }
  .title {
    font-size: 16px;
  }
  .desc {
    margin: 5px 0 10px;
  }
  .desc-detail {
    margin: 10px 0 5px;
  }
  .font-bolder {
    font-weight: bolder;
  }
  ::v-deep .el-button {
    color: #005397;
    margin-top: 8px;
    padding: 8px 16px;
  }
  .add-condi {
    margin: 20px 0 10px;
  }
}
</style>