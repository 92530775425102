<!--
  报表列表页面
  关于操作的权限：
  报表：
    1.新建报表&新建报表文件夹&复制报表
    受新建报表权限控制
    2.导出报表
    受导出报表权限控制
    3.编辑、删除、重命名、转移文件夹、添加到仪表板
    受文件夹编辑权限控制
    4.运行
    受运行报表权限控制（点击报表名和运行按钮）
-->
<template>
  <div class="body">
    <div class="reportList">
      <div class="reportList_content">
        <div class="reportList_top">
          <div class="top_tltle">
            <div>{{ $t("label.visualpage.btype") }}</div>
            <div>{{ labelTitle }}</div>
            <div>{{ page.total }}{{ $t("label.file.items") }}</div>
          </div>
          <div class="right_infos">
            <div class="top_search">
              <!-- 回车搜索 -->
              <el-input
                :placeholder="placeholderVal"
                v-model="form.input"
                @keyup.enter.native="reportSelectSearch"
                class="input-with-select"
              >
                <!-- 回车搜索和实时监听搜索 -->
                <!-- <el-input
              :placeholder="placeholderVal"
              suffix-icon="el-icon-search"
              v-model="form.input"
              @keyup.enter.native="reportSelectSearch"
              @input="realTimeSearch"
            > -->
                <el-select
                  v-model="reportSelectFileValue"
                  slot="prepend"
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                  @change="reporSearchFolder"
                >
                  <el-option
                    v-for="item in selectFile"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-input>
              <svg
                class="icon searchBtn"
                aria-hidden="true"
                @click="reportSelectSearch"
              >
                <use href="#icon-search"></use>
              </svg>
            </div>
            <!-- 新建报表权限控制：新建报表&新建报表文件夹&复制报表 -->
            <div class="top_butts" v-if="reportOperation.new">
              <el-button type="primary" size="mini" @click="newReport">{{
                $t("message.newreport")
              }}</el-button>
              <el-button type="primary" size="mini" @click="clickNewFolder">{{
                $t("label.reportlist.newfolder")
              }}</el-button>
            </div>
            <!-- 只有管理员才可以设置标题列 -->
            <div
              class="set_img"
              @click.stop="clickSet"
              :class="{ active_img: setTo }"
              v-if="isAdministrator"
            >
              <!-- 设置图标 -->
              <svg class="icon" aria-hidden="true" v-if="!setTo">
                <use href="#icon-set"></use>
              </svg>
              <svg class="icon" aria-hidden="true" v-else>
                <use href="#icon-seted"></use>
              </svg>
              <!-- 设置标题列的弹框 -->
              <div
                class="set_bounce"
                v-show="setTo || setToConfirm"
                @click.stop="keepOpen"
              >
                <div class="top_title">{{ $t("vue_label_report_list") }}</div>
                <div class="all_fields">
                  <div class="fixed_field">
                    <el-checkbox v-model="form.name" disabled>{{
                      $t("label.nameofreport")
                    }}</el-checkbox>
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-suo"></use>
                    </svg>
                  </div>
                  <div class="fixed_field">
                    <el-checkbox v-model="form.filed" disabled>{{
                      $t("vue_label_report_reportfolder")
                    }}</el-checkbox>
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-suo"></use>
                    </svg>
                  </div>
                  <div class="fixed_field">
                    <el-checkbox v-model="form.subscribed" disabled>{{
                      $t("label.reportsSubscription.status")
                    }}</el-checkbox>
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-suo"></use>
                    </svg>
                  </div>
                  <el-checkbox-group v-model="form.allField">
                    <el-checkbox
                      v-for="item in reportHeader"
                      :key="item.apiname"
                      :label="item.api"
                      v-show="
                        item.api != 'reportLabel' &&
                        item.api != 'folderLabel' &&
                        item.api != 'issubscribed'
                      "
                      >{{ item.apiname }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div class="bottm_butts">
                  <el-button size="mini" @click.stop="clickConfirm">{{
                    $t("button_partner_cancel")
                  }}</el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click.stop="clickConfirm"
                    @click="colSubmit"
                    >{{ $t("label.ems.confirm") }}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reportList_bottom">
          <div class="left_lists">
            <div class="all_reports">
              <div class="title_div">{{ $t("label.visualpage.btype") }}</div>
              <div class="all_data_reports">
                <div
                  @click="
                    clickLabel(
                      $t('Dashboard_View_RecentlyView'),
                      'recentReport'
                    )
                  "
                  :class="{ active: 'recentReport' == reportCur }"
                >
                  {{ $t("Dashboard_View_RecentlyView") }}
                </div>
                <div
                  @click="
                    clickLabel(
                      $t('label.report.items.i.created'),
                      'thereporticreated'
                    )
                  "
                  :class="{ active: 'thereporticreated' == reportCur }"
                >
                  {{ $t("label.report.items.i.created") }}
                </div>
                <div
                  @click="clickLabel($t('label.allreport'), 'allreports')"
                  :class="{ active: 'allreports' == reportCur }"
                >
                  {{ $t("label.allreport") }}
                </div>
              </div>
            </div>
            <div class="all_folders">
              <div class="title_div">{{ $t("report_label_folder") }}</div>
              <div class="all_data_folders">
                <!-- 固定的文件夹 -->
                <div class="top_folder_datas" v-if="topFolderList.length > 0">
                  <div
                    class="each_folder"
                    v-for="(item, index) in topFolderList"
                    :key="index"
                    :class="{ active: item.id === chooseFolder }"
                  >
                    <div
                      class="folder_name"
                      @click="clickFolder(item, index, 'top')"
                    >
                      {{ item.name }}
                    </div>
                    <div class="operation_img">
                      <!-- 操作图片 -->
                      <el-popover
                        placement="bottom-start"
                        :visible-arrow="false"
                        width="113"
                        trigger="hover"
                        popper-class="myPopover"
                      >
                        <svg
                          class="icon"
                          aria-hidden="true"
                          slot="reference"
                          @mouseover="showOperation('top', index)"
                          @mouseout="hideOperation('top', index)"
                        >
                          <use href="#icon-xiala"></use>
                        </svg>
                        <!-- 操作弹框 -->
                        <div class="folder_operation">
                          <el-button
                            @click="clickFixToTop('top', item, index)"
                            v-if="index !== 0"
                            >{{ $t("label.reportlist.top") }}</el-button
                          >
                          <el-button @click="clickCancelFix(item, index)">{{
                            $t("label.reportlist.untop")
                          }}</el-button>
                          <el-button
                            @click="editFolder('top', item, index)"
                            :disabled="
                              item.isedit == 'false' || item.isTemplateFolder
                            "
                            >{{ $t("pagecreator_page_button_edit") }}</el-button
                          >
                          <el-button
                            @click="clickDeleteFolder('top', item, index)"
                            :disabled="item.isdelete == 'false'"
                            >{{
                              $t("component_setup_tabs_label_delete")
                            }}</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </div>

                <!-- 未固定的文件夹 -->
                <div class="bottom_folder_datas">
                  <div
                    class="each_folder"
                    v-for="(item, index) in noTopFolderList"
                    :key="index"
                    :class="{ active: item.id === chooseFolder  }"
                  >
                    <div
                      class="folder_name"
                      @click="clickFolder(item, index, 'noTop')"
                    >
                      {{ item.name }}
                    </div>
                    <div
                      class="operation_img"
                      v-show="
                        item.name != $t('message.myselfreport') &&
                        item.name != $t('label.notfiledpublicreport')
                      "
                    >
                      <el-popover
                        placement="bottom-start"
                        :visible-arrow="false"
                        width="113"
                        trigger="hover"
                        popper-class="myPopover"
                      >
                        <svg
                          class="icon"
                          aria-hidden="true"
                          slot="reference"
                          @mouseover="showOperation('noTop', index)"
                          @mouseout="hideOperation('noTop', index)"
                        >
                          <use href="#icon-xiala"></use>
                        </svg>
                        <div class="folder_operation">
                          <el-button
                            @click="clickFixToTop('noTop', item, index)"
                            >{{ $t("label.reportlist.top") }}</el-button
                          >
                          <el-button
                            @click="editFolder('noTop', item, index)"
                            :disabled="item.isedit == 'false'"
                            >{{ $t("pagecreator_page_button_edit") }}</el-button
                          >
                          <el-button
                            @click="clickDeleteFolder('noTop', item, index)"
                            :disabled="item.isdelete == 'false'"
                            >{{
                              $t("component_setup_tabs_label_delete")
                            }}</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right_table">
            <!-- 骨架屏 -->
            <Wang-skeleton
              type="line"
              :options="skeletonOptions"
              v-if="wangloading"
            >
            </Wang-skeleton>
            <el-table
              :data="reportData"
              style="width: 100%"
              v-loading="loading"
              v-if="!wangloading"
            >
              <el-table-column
                v-for="item in reportHeader.filter((header) => {
                  return (
                    header.checked == 'true' || header.api == 'issubscribed'
                  );
                })"
                :key="item.api"
                :prop="item.api"
                :label="item.apiname"
                min-width="100"
                show-overflow-tooltip
                sortable
              >
                <template slot-scope="scope">
                  <!-- 仪表板名称 -->
                  <span
                    v-if="item.api == 'reportLabel'"
                    :class="{ word_style: reportOperation.run }"
                  >
                    <span
                      v-if="scope.row.isOptimization == 'true'"
                      class="xingColor"
                      >*</span
                    >
                    <a
                      @click.exact="clickReportName(scope.row,false)"
                      @click.ctrl.exact="clickReportName(scope.row, true)"
                      @click.meta.exact="clickReportName(scope.row, true)"
                    >
                      {{ scope.row[item.api] }}</a
                    >
                  </span>
                  <!-- 是否订阅，字符串转布尔型用！！ -->
                  <span v-else-if="item.api == 'issubscribed'">
                    <el-checkbox
                      v-model="scope.row.issubscribed"
                      disabled
                      class="check-color"
                    ></el-checkbox>
                  </span>
                  <span v-else>{{ scope.row[item.api] }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('label_partner_all_operate')"
                min-width="70"
              >
                <template slot-scope="scope">
                  <!-- 当所有操作权限均没有时，直接不显示操作图标 -->
                  <div
                    class="operation_butts"
                    v-if="
                      reportOperation.new ||
                      reportOperation.run ||
                      reportOperation.export ||
                      getReportFolderPermissions(scope.row.folderId, allFolders)
                        .isedit
                    "
                  >
                    <el-popover
                      placement="bottom-end"
                      :visible-arrow="false"
                      trigger="hover"
                      popper-class="myPopover"
                    >
                      <!-- 操作 -->
                      <el-card class="box-card moreMenus">
                        <ul>
                          <!-- 运行 -->
                          <li
                            @click="clickReportName(scope.row)"
                            v-if="reportOperation.run"
                          >
                            {{ $t("label.runreport") }}
                          </li>
                          <!-- 导出 -->
                          <li
                            @click="clickExport(scope.row)"
                            v-if="
                              reportOperation.export &&
                              scope.row.reporttypename !== $t('label.ratio')
                            "
                          >
                            {{ $t("label.reportexport") }}
                          </li>
                          <!-- 重命名 -->
                          <li
                            @click="clickRenameReport(scope.row, 'rename')"
                            v-if="
                              getReportFolderPermissions(
                                scope.row.folderId,
                                allFolders
                              ).isedit && !scope.row.isTemplateReport
                            "
                          >
                            {{ $t("label.rename") }}
                          </li>
                          <!-- 转移 -->
                          <li
                            @click="clickRenameReport(scope.row, 'transfer')"
                            v-if="
                              getReportFolderPermissions(
                                scope.row.folderId,
                                allFolders
                              ).isedit
                            "
                          >
                            <!-- 转移文件夹 -->
                            {{ $t("vue_report_dashboard_transfer") }}
                          </li>
                          <li
                            @click="editClick(scope.row)"
                            v-if="
                              getReportFolderPermissions(
                                scope.row.folderId,
                                allFolders
                              ).isedit && scope.row.isOptimization == 'false'
                            "
                          >
                            <!-- 编辑 -->
                            {{ $t("pagecreator_page_button_edit") }}
                          </li>
                          <!-- 删除 -->
                          <li
                            @click="deleteReport(scope.row)"
                            v-if="
                              getReportFolderPermissions(
                                scope.row.folderId,
                                allFolders
                              ).isedit
                            "
                          >
                            {{ $t("component_setup_tabs_label_delete") }}
                          </li>
                          <!-- 复制 -->
                          <li
                            @click="clickCopyReport(scope.row)"
                            v-if="reportOperation.new"
                          >
                            {{ $t("label.clone") }}
                          </li>
                          <!-- 添加到仪表盘 -->
                          <li
                            @click="clickAddDashboard(scope.row)"
                            v-if="
                              scope.row.reporttypename !==
                                $t('label.tabularreport') &&
                              scope.row.reporttypename !== $t('label.ratio') &&
                              getReportFolderPermissions(
                                scope.row.folderId,
                                allFolders
                              ).isedit
                            "
                          >
                            {{ $t("label.report.action.addtodashboard1") }}
                          </li>
                          <!-- 订阅 -->
                          <li
                            v-if="
                              !scope.row.issubscribed &&
                              scope.row &&
                              scope.row.reporttypename !== $t('label.ratio')
                            "
                            @click="clickSubscribe(scope.row)"
                          >
                            {{ $t("label.reportsSubscription.entrance") }}
                          </li>
                          <!-- 取消订阅 -->
                          <li
                            v-if="scope.row.issubscribed"
                            @click="unsubscribe(scope.row)"
                          >
                            {{ $t("label.reportsSubscription.unsubscribe") }}
                          </li>
                          <!-- 编辑订阅 -->
                          <li
                            v-if="scope.row.issubscribed"
                            @click="editsubscribe(scope.row)"
                          >
                            {{ $t("label.reportsSubscription.editsubscribe") }}
                          </li>
                        </ul>
                      </el-card>
                      <svg class="icon" aria-hidden="true" slot="reference">
                        <use href="#icon-xiala"></use>
                      </svg>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="isHasOptimization" class="notes">
              {{ $t("label.import.index.notice") }}
              <span class="text-red">*</span
              >{{ $t("front-reportlist-v1-optimizedReport") }}
            </div>
            <div class="bottom_page">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.pageNum"
                :page-sizes="[5, 10, 30, 50]"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total"
              ></el-pagination>
            </div>
          </div>
        </div>

        <!-- 删除报表弹框 -->
        <deleteReport
          :deleteReportTo="deleteReportTo"
          :reportInfos="reportInfos"
          :getDataLists="getDataLists"
          :confirmdelete="confirmdelete"
          @deleteReportCancel="deleteReportCancel"
          @deleteReportConfirms="deleteReportConfirms"
          ref="deleteReportRef"
        />
        <!-- 当有图表在引用报表时，该报表删除需二次确认，连同图表一起删除 -->
        <second-delete
          :secondDeleteTo="secondDeleteTo"
          :markedWords="markedWords"
          @secondDeleteClose="secondDeleteClose"
          @secondDeleteConfirm="secondDeleteConfirm"
        ></second-delete>
        <!-- 重命名报表弹框 -->
        <renameReport
          :renameReportTo="renameReportTo"
          :reportInfos="reportInfos"
          :getDataLists="getDataLists"
          :allFolders="allFolders"
          :titleType="titleType"
          @renameReportCancel="renameReportCancel"
        />
        <!-- 导出弹框 -->
        <export-report
          :exportReportTo="exportReportTo"
          :exportReportMax="exportReportMax"
          :exportLoad="exportLoad"
          :reportId="reportId"
          :totalPages="totalPages"
          :totalSize="totalSize"
          @exportBoxCancels="exportBoxCancels"
        />
        <!-- 复制报表弹框 -->
        <copyReport
          :copyReportTo="copyReportTo"
          :reportInfos="reportInfos"
          :allFolders="allFolders"
          :getDataLists="getDataLists"
          @copyReportCancel="copyReportCancel"
        />
        <!-- 添加到仪表盘弹框 -->
        <funcNotOnline
          :funcNotOnlineTo="funcNotOnlineTo"
          :reportIdFromReport="reportId"
          @funcNotOnlineCancel="funcNotOnlineCancel"
          ref="addToDashboard"
        />
        <!-- 删除文件夹弹框 -->
        <deleteFolder
          :deleteFolderTo="deleteFolderTo"
          :folderInfo="folderInfo"
          :getFolders="getFolders"
          @deleteFolderCancle="deleteFolderCancle"
        />
        <!-- 新建文件夹弹框 -->
        <newFolder
          :newFolderTo="newFolderTo"
          :header="folderHeader"
          :folderId="newFolderId"
          :getFolders="getFolders"
          :getDataLists="getDataLists"
          @newFolderCancel="newFolderCancel"
        />
        <!-- 报表订阅 -->
        <reportSubscribe
          ref="reportSubscribeAlert"
          :reportId="reportId"
          :reportSubscribeType="reportSubscribeType"
          :reportSubscribeTo="reportSubscribeTo"
          @reportSubscribeCancel="reportSubscribeCancel"
          @reportSubscribeConfirm="reportSubscribeConfirm"
          :isreporEdit="isreporEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 报表组件
 */
import deleteReport from "./components/deleteReport";
import renameReport from "./components/renameReport";
import exportReport from "./components/exportReport";
import copyReport from "./components/copyReport";
import funcNotOnline from "./components/funcNotOnline";
import deleteFolder from "./components/deleteFolder";
import newFolder from "./components/newFolder";
import reportSubscribe from "./components/reportSubscribe"; // 报表订阅
import * as types from "@/store/mutations-types";
import reportFolderPermissionsMin from "@/mixin/reportFolderPermissions.js"; // 关于报表文件夹权限：编辑，删除
import { unsubscribe, gotoSubscribe } from "./api";
import secondDelete from "@/components/ButtonWrapper/secondDelete";
import { mapState } from "vuex";
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import {
  getFolder,
  getDataList,
  topFolder,
  setReportListCols,
  getReportDataByDetail,
} from "../api.js";

export default {
  components: {
    deleteReport,
    renameReport,
    exportReport,
    copyReport,
    funcNotOnline,
    deleteFolder,
    newFolder,
    reportSubscribe,
    secondDelete,
  },
  mixins: [reportFolderPermissionsMin],
  data() {
    return {
      titleType: "", //报表操作：转移，重命名
      loading: false,
      wangloading:true,//骨架屏开关
      // 骨架屏数据
      skeletonOptions: {
        active: true,
        row: 18,
        column: 4,
        width: "16vw",
        height: "20px",
        justifyContent: "space-around",
      },
      form: {
        input: "",
        name: true,
        filed: true,
        subscribed: true,
        allField: [],
      },
      allFolders: [], //报表文件夹数据
      topFolderList: [], //报表顶部文件夹数据
      noTopFolderList: [], //报表未固定到顶部的文件夹数据
      reportCur: "", //最近查看，所有报表，我创建的报表
      chooseFolder:'',//选中的文件夹id
      labelTitle: this.$i18n.t("Dashboard_View_RecentlyView"),
      reportData: [],
      reportHeader: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      deleteReportTo: false, //删除文件夹弹框
      setTo: false,
      setToConfirm: false, //确认关闭
      renameReportTo: false, //重命名弹框
      exportReportTo: false, //导出弹框
      exportReportMax:0,//导出报表最大条数
      exportLoad: false, // 导出的loading
      copyReportTo: false, //复制报表弹框
      funcNotOnlineTo: false, //添加到仪表盘弹框
      operationTopArr: [false],
      operationNoTopArr: [false],
      operationCur: 1,
      deleteFolderTo: false, //删除文件夹弹框
      folderId: "", //文件夹id
      newFolderId: "",
      folderInfo: {},
      newFolderTo: false,
      folderHeader: this.$i18n.t("label.brief.new"),
      reportInfos: {},
      datasApi: "",
      reportId: "", //报表id
      totalPages: "", //报表总页数
      totalSize: 0, // 报表总条数
      reportSelectFileValue: "reportName", //搜索选中的值
      //对报表搜索还是报表所在的文件夹进行搜索
      reporSelectType: "reportName", //选中的类型
      selectFile: [
        {
          id: "fileName",
          name: this.$i18n.t("label.foldername"),
        },
        {
          id: "reportName",
          name: this.$i18n.t("label.nameofreport"),
        },
      ],
      isAdministrator: false, // 是否属于管理员
      reportSubscribeTo: false, // 报表订阅弹框
      reportSubscribeType: "", //报表类型
      isreporEdit: "", //订阅还是编辑订阅
      secondDeleteTo: false, // 报表二次删除弹框
      markedWords: "", // 报表二次删除的提示语
      confirmdelete: false,
      isHasOptimization: false, // 是否有优化过的报表，默认false，则不显示注
    };
  },
  watch: {
    // 监听路由传参，是否携带文件夹id
    $route: {
      handler: function (route) {
        // 进入报表列表页，设置默认页数设置1,条数10,当前选中文件夹：最近查看
        if (route.fullPath == "/reportObject/reportList") {
          this.$store.commit("setreportPageIndex", 1);
          this.$store.commit("setReportPageSize", 10);
          this.$store.commit("setreportChooseFolder", "recentReport");
          this.$store.commit("setreportSearchContent", {});
          this.getFolders();
        } else {
          // 从报表编辑页返回到列表页，返回之前所选页数，页码和文件夹,搜索内容
          if (this.$store.getters.getreportPageIndex) {
            this.page.pageNum = this.$store.getters.getreportPageIndex;
          }
          if (this.$store.getters.getReportPageSize) {
            this.page.pageSize = this.$store.getters.getReportPageSize;
          }
          // 1、有搜索内容，分别调用文件夹接口和报表列表接口
          // 2、没有搜索内容时，直接调取文件夹接口
          if (
            this.$store.getters.getreportSearchContent &&
            this.$store.getters.getreportSearchContent.value &&
            this.$store.getters.getreportSearchContent.type
          ) {
            this.form.input = this.$store.getters.getreportSearchContent.value;
            this.reporSelectType =
              this.$store.getters.getreportSearchContent.type;
            this.reportSelectFileValue =
              this.$store.getters.getreportSearchContent.type;
            // 1、按照报表名搜索时，显示全部文件夹，搜索报表列表
            if (this.reporSelectType === "reportName") {
              this.allgetFolders();
              this.getDataLists();
            } else if (this.reporSelectType === "fileName") {
              // 2、按照文件名搜索
              this.getFolders(this.$store.getters.getreportChooseFolder);
            }
          } else {
            if (this.$store.getters.getreportChooseFolder) {
              this.getFolders(this.$store.getters.getreportChooseFolder);
            } else {
              // 按照报表名搜索时，没有当前选中文件夹，进入报表详情页返回时，默认返回最近查看
              this.getFolders();
            }
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("label.visualpage.btype"));
    this.$store.commit(
      types.SET_TAB_NAME,
      this.$i18n.t("label.visualpage.btype")
    );
    // 判断是否是管理员
    if (this.$store.state.userInfoObj.profileId === "aaa000001") {
      this.isAdministrator = true;
    } else {
      this.isAdministrator = false;
    }
  },
  methods: {
    //回车搜索方法
    reportSelectSearch() {
      //按照报表所在文件夹搜索
      if (this.reporSelectType === "fileName") {
        // 按照文件夹搜索后，清空内容搜索，会展全部文件夹，默认选中之前点击过的文件夹
        this.getFolders(this.$store.getters.getreportChooseFolder);
        //按照报表名搜索
      } else if (this.reporSelectType === "reportName") {
        this.folderId = "";
        this.chooseFolder="";
        this.reportCur = "";
        this.getDataLists();
      }
    },
    //记录当前要搜索的类型
    reporSearchFolder(val) {
      this.reporSelectType = val;
      this.form.input = "";
      if (val == "reportName") {
        this.allgetFolders();
      }
    },
    // 获取报表首页列表
    async getDataLists() {
      this.loading = true;
      //设置中间变量防止input框清空
      let input = "";
      if (this.reporSelectType === "fileName") {
        input = "";
      } else if (this.reporSelectType === "reportName" && this.form.input) {
        input = this.form.input;
      }
      let params = {
        folderId: this.folderId,
        orderField: "",
        orderType: "",
        page: this.page.pageNum,
        pageSize: this.page.pageSize,
        searchKeyWord: input,
      };
      let dataObject = await getDataList(params);
      this.loading = false;
      let data = dataObject.data;
      if (dataObject.result) {
        this.reportHeader = data.reportHeader;
        // 获取要回显的数据
        this.form.allField = [];
        data.reportHeader.map((item) => {
          if (item.checked == "true") {
            this.form.allField.push(item.api);
          }
        });
        this.reportData = data.reportData;
        // 对issubscribed是否订阅属性做个处理
        this.reportData.map((item) => {
          if (item.issubscribed) {
            item.issubscribed = true;
          } else {
            item.issubscribed = false;
          }
        });
        // 筛查是否有优化过的报表
        this.reportData.filter((item) => {
          if (item.isOptimization && item.isOptimization == "true") {
            this.isHasOptimization = true;
            return;
          }
        });
        this.page.total = data.totalnum;
        this.wangloading=false;
      } else {
        this.reportHeader = [];
        this.reportData = [];
        this.page.total = 0;
        this.wangloading=false;
      }
    },
    //设置报表列表列
    async colSubmit() {
      this.form.allField.push("reportLabel", "folderLabel");
      let params = {
        cols: this.form.allField.join(","),
      };
      await setReportListCols(params);
      this.getDataLists();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getDataLists();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getDataLists();
    },
    // 切换搜索类型恢复全部文件夹
    async allgetFolders(value) {
      var params = {
        searchKeyWord: value ? value : "",
      };
      let dataObject = await getFolder(params);
      let data = dataObject.data;
      // 获取固定到顶部和未固定到顶部的文件夹
      this.topFolderList = data.topFolderList;
      this.noTopFolderList = data.noTopFolderList;
      // 获取所有文件夹
      this.allFolders = data.allFolderlist;
    },
    // 获取报表文件夹
    async getFolders(choosefolderId) {
      var params = {
        searchKeyWord: this.form.input,
      };
      let dataObject = await getFolder(params);
      let data = dataObject.data;
      // 获取固定到顶部和未固定到顶部的文件夹
      this.topFolderList = data.topFolderList;
      this.noTopFolderList = data.noTopFolderList;
      // 获取所有文件夹
      this.allFolders = data.allFolderlist;
      if (this.form.input && !choosefolderId) {
        if (
          this.topFolderList.length === 0 &&
          this.noTopFolderList.length > 0
        ) {
          this.reportCur = "";
          this.chooseFolder = this.noTopFolderList[0].id;
          this.folderId = this.noTopFolderList[0].id;
        } else if (
          this.noTopFolderList.length === 0 &&
          this.topFolderList.length > 0
        ) {
          this.reportCur = "";
          this.chooseFolder = this.topFolderList[0].id;
          this.folderId = this.topFolderList[0].id;
        } else if (
          this.topFolderList.length > 0 &&
          this.noTopFolderList.length > 0
        ) {
          this.reportCur = "";
          this.chooseFolder = this.topFolderList[0].id;
          this.folderId = this.topFolderList[0].id;
        }
        // 搜索文件夹后，默认选中搜索后的第一个文件夹
        this.$store.commit("setreportChooseFolder", this.folderId);
      } else if (
        choosefolderId == "recentReport" ||
        choosefolderId == "thereporticreated" ||
        choosefolderId == "allreports"
      ) {
        // 从报表编辑页返回到列表页时，所在报表是从最近查看，我创建的报表，所有报表中打开的
        this.chooseFolder = ""
        this.reportCur = choosefolderId;
        this.folderId = choosefolderId;
      } else if (
        choosefolderId != "recentReport" &&
        choosefolderId != "thereporticreated" &&
        choosefolderId != "allreports" &&
        choosefolderId
      ) {
        this.folderId = choosefolderId;
        // 从报表编辑页返回到列表页时，携带文件夹id，获取文件夹id下标设置选中样式
        // 固定在顶部的文件夹
        data.topFolderList.map((topitem) => {
          if (topitem.id == choosefolderId) {
            this.chooseFolder = topitem.id
          }
        });
        // 未固定到顶部的文件夹
        data.noTopFolderList.map((item) => {
          if (item.id == choosefolderId) {
            this.chooseFolder = item.id
          }
        });
        this.$forceUpdate();
        // 清空未选中的状态
        if (this.chooseFolder) {
          this.reportCur = "";
        } 

      } else if (!choosefolderId) {
        // 默认最近查看
        this.reportCur = "recentReport";
        this.folderId = "recentReport";
      }
      this.getDataLists();
    },
    //点击报表列表
    async clickLabel(item, index) {
      this.reportCur = index;
      this.chooseFolder = "";
      this.folderId = index;
      this.page.pageNum = 1;
      this.form.input = "";
      this.$store.commit("setreportChooseFolder", this.reportCur);
      await this.getDataLists();
      this.labelTitle = item;
    },
    // 点击报表文件夹
    async clickFolder(item, index, type) {
      if (type === "top") {
        this.chooseFolder = item.id;
        this.reportCur = "";
      } else {
        this.chooseFolder = item.id;
        this.reportCur = "";
      }
      this.page.pageNum = 1;
      this.folderId = item.id;
      this.$store.commit("setreportChooseFolder", this.folderId);
      if (this.reporSelectType === "reportName"){
        this.form.input = "";
      }
      await this.getDataLists();
      this.labelTitle = item.name;
    },
    // 点击报表名称
    async clickReportName(item,flag) {
      // 若是没有运行报表权限，则不需要跳转至运行页
      if (!this.reportOperation.run) return;
      // 跳转页面前保存当前的页数,条数，当前选中文件夹,搜索内容
      this.$store.commit("setreportPageIndex", this.page.pageNum);
      this.$store.commit("setReportPageSize", this.page.pageSize);
      if (this.reportCur) {
        this.$store.commit("setreportChooseFolder", this.reportCur);
      } else {
        this.$store.commit("setreportChooseFolder", this.folderId);
      }
      let searchcontent = {
        value: this.form.input,
        type: this.reporSelectType,
      };
      this.$store.commit("setreportSearchContent", searchcontent);
      // 在服务控制台应用程序下,ctrl+单击生成且不打开一级菜单，单击生成并打开一级菜单
      if(this.$store.state.navigationStyle&&flag!==""){
        let routerPath=`/reportObject/editReport/run/${item.reportId}/${item.folderId}`
        let menuObj = {
          name:item.reportLabel,
          id: item.reportId,
          routerPath: routerPath,
          query: {
              isOptimization:item.isOptimization
            },
          }
        
        window.$CCDK.CCMenu.addMenu1(menuObj);
        if(flag===false){
          this.$router.push({
            path: `/reportObject/editReport/run/${item.reportId}/${item.folderId}`,
            query: {
              isOptimization: item.isOptimization == "true" ? "true" : "false", // 优化过的报表没有编辑功能,且详情用的是优化后查询SQL的接口
            },
          });
        }else{
          this.consoleData.data.level1Id = "menu";
        }
      }else{
        this.$router.push({
          path: `/reportObject/editReport/run/${item.reportId}/${item.folderId}`,
          query: {
            isOptimization: item.isOptimization == "true" ? "true" : "false", // 优化过的报表没有编辑功能,且详情用的是优化后查询SQL的接口
          },
        });
      }
    },
    // 点击设置图标
    clickSet() {
      this.setToConfirm = !this.setToConfirm;
      this.setTo = !this.setTo;
    },
    // 隐藏设置图标
    clickConfirm() {
      this.setToConfirm = false;
      this.setTo = false;
    },
    //保持菜单打开
    keepOpen() {
      this.setToConfirm = true;
    },
    //编辑跳转
    editClick(item) {
      this.$router.push({
        path: `/reportObject/editReport/edit/${item.reportId}/${item.folderId}`,
        query: {},
      });
    },
    // 点击导出
    async clickExport(data) {
      // 点击导出先显示弹框，出现loading，等接口请求成功再将loading取消
      this.exportReportTo = true;
      this.exportLoad = true;
      this.reportId = data.reportId;
      let params = {
        id: this.reportId,
      };
      let dataObject = await getReportDataByDetail(params);
      let datas = dataObject.data;
      let total = datas.dataSize; // 报表总条数
      this.totalSize = Number(total); // 总条数
      this.exportLoad = false;
      if(window.Glod.EXPORT_REPORT_MAX_SIZE){
        let newExps=[];//报表导出数据配置对象
        let exps= window.Glod.EXPORT_REPORT_MAX_SIZE.split(",");
        // 字符串转为对象
        exps&&exps.map((item)=>{
          newExps.push(eval("({" + item + "})"))
        })
        // 筛选出当前用户可导出报表数据的配置信息
        let orgData=newExps.filter((item)=>{
          return item[this.$CCDK.CCUser.getUserInfo().orgId]
        })
        // 根据配置信息设置报表导出最大值
        if(orgData&&orgData.length>0){
          this.exportReportMax=orgData[0][this.$CCDK.CCUser.getUserInfo().orgId];
        } else {
          this.exportReportMax=5000;
        }
      }else{
        this.exportReportMax=5000;
      }
      this.totalPages = Math.ceil(Number(total) / this.exportReportMax); // 总页数
    },
    // 点击重命名/转移报表
    clickRenameReport(item, type) {
      this.titleType = type;
      this.renameReportTo = true;
      this.reportInfos = item;
    },
    // 点击删除报表
    deleteReport(item) {
      this.deleteReportTo = true;
      this.reportInfos = item;
    },
    // 点击添加到仪表盘
    clickAddDashboard(data) {
      this.funcNotOnlineTo = true;
      this.reportId = data.reportId;
      this.$refs.addToDashboard.getDashboardFolderList();
    },
    // 点击订阅报表
    clickSubscribe(data) {
      this.reportSubscribeTo = true;
      this.reportId = data.reportId;
      this.isreporEdit = "subscribe";
      this.reportSubscribeType = data.reporttypename;
      this.$refs.reportSubscribeAlert.gotoSubscribe(this.reportId);
      this.$refs.reportSubscribeAlert.getUserName();
      this.$refs.reportSubscribeAlert.frequency = "weekly";
      this.$refs.reportSubscribeAlert.checkboxweek.push(this.$i18n.t("monday"));
      this.$refs.reportSubscribeAlert.monthTime = "relative";
      this.$refs.reportSubscribeAlert.monthDay = "monday";
      this.$refs.reportSubscribeAlert.monthWeek = this.$i18n.t(
        "label.schedulejob.setup.schedule.st1"
      );
    },
    // 编辑订阅
    editsubscribe(data) {
      this.reportSubscribeTo = true;
      this.reportId = data.reportId;
      this.isreporEdit = "edit";
      this.$refs.reportSubscribeAlert.getUserName();
      this.$refs.reportSubscribeAlert.gotoSubscribe(this.reportId);
      this.reportSubscribeType = data.reporttypename;
    },
    // 取消订阅，提示取消订阅成功，失败
    async unsubscribe(data) {
      // 调取查询报表接口，获取订阅id，根据id取消订阅
      let params = {
        reportid: data.reportId,
      };
      let res = await gotoSubscribe(params);
      if (res.result) {
        let param = {
          id: res.data.id,
        };
        let resData = await unsubscribe(param);
        if (resData.result) {
          this.$message.success(
            this.$i18n.t("label.reportsSubscription.unsubscribe") +
              this.$i18n.t("label.systemlog.s")
          );
          this.getDataLists();
        } else {
          this.$message.error(
            this.$i18n.t("label.reportsSubscription.unsubscribe") +
              this.$i18n.t("label.import.index.failed")
          );
        }
      }
    },
    //确定订阅
    reportSubscribeConfirm() {
      this.getDataLists();
    },
    // 关闭订阅弹框
    reportSubscribeCancel() {
      this.reportSubscribeTo = false;
    },
    // 点击复制报表
    clickCopyReport(item) {
      this.copyReportTo = true;
      this.reportInfos = item;
    },
    //新建报表
    newReport() {
      this.$router.push({
        path: "/reportObject/dataSource",
        params: {},
      });
    },
    // 新建报表文件夹
    clickNewFolder() {
      this.newFolderTo = true;
      this.folderHeader = this.$i18n.t("label.brief.new");
      this.newFolderId = "";
    },
    // 点击固定到顶部
    clickFixToTop(type, item, index) {
      this.operationCur = 1;
      if (type === "top") {
        this.operationTopArr[index] = false;
      } else {
        this.operationNoTopArr[index] = false;
      }
      this.$forceUpdate();
      this.newFolderId = item.id;
      this.topFolder(true);
    },
    // 取消固定
    clickCancelFix(item, index) {
      this.operationCur = 1;
      this.operationTopArr[index] = false;
      this.$forceUpdate();
      this.newFolderId = item.id;
      this.topFolder(false);
    },
    //报表文件夹置顶和取消置顶接口
    async topFolder(value) {
      let params = {
        folderId: this.newFolderId,
        isTop: value,
      };
      await topFolder(params);
      this.getFolders(this.folderId);
    },
    // 点击编辑文件夹
    editFolder(type, item, index) {
      this.operationCur = 3;
      if (type === "top") {
        this.operationTopArr[index] = false;
      } else {
        this.operationNoTopArr[index] = false;
      }
      this.$forceUpdate();
      this.newFolderTo = true;
      this.folderHeader = this.$i18n.t("pagecreator_page_button_edit");
      this.newFolderId = item.id;
    },
    // 点击删除文件夹
    clickDeleteFolder(type, item, index) {
      this.operationCur = 4;
      if (type === "top") {
        this.operationTopArr[index] = false;
      } else {
        this.operationNoTopArr[index] = false;
      }
      this.$forceUpdate();
      this.deleteFolderTo = true;
      this.folderInfo = item;
    },
    // 关闭报表文件夹弹框
    newFolderCancel() {
      this.newFolderTo = false;
    },
    // 删除报表失败，进行二次删除
    deleteReportConfirms(returnInfo) {
      this.markedWords = returnInfo;
      this.secondDeleteTo = true;
    },
    // 关闭删除弹框
    deleteReportCancel() {
      this.deleteReportTo = false;
      this.secondDeleteTo = false;
      this.confirmdelete = false;
    },
    // 报表二次删除确认
    secondDeleteConfirm() {
      this.confirmdelete = true; // 确认连同图表一起删除
      setTimeout(() => {
        this.$refs.deleteReportRef.deleteReportConfirm(); // 再次进行删除
      }, 0);
    },
    // 报表二次删除确认弹框隐藏
    secondDeleteClose() {
      this.secondDeleteTo = false;
      this.confirmdelete = false;
    },
    // 关闭重命名报表弹框
    renameReportCancel() {
      this.renameReportTo = false;
    },
    // 关闭复制报表弹框
    copyReportCancel() {
      this.copyReportTo = false;
    },
    // 关闭添加到仪表盘弹框
    funcNotOnlineCancel() {
      this.funcNotOnlineTo = false;
    },
    // 关闭导出弹框
    exportBoxCancels() {
      this.exportReportTo = false;
    },
    // 显示文件夹的操作
    showOperation(type, index) {
      if (type === "top") {
        this.operationTopArr[index] = true;
      } else {
        this.operationNoTopArr[index] = true;
      }
      this.$forceUpdate();
    },
    // 隐藏文件夹的操作
    hideOperation(type, index) {
      if (type === "top") {
        this.operationTopArr[index] = false;
      } else {
        this.operationNoTopArr[index] = false;
      }
      this.$forceUpdate();
    },
    // 关闭删除文件夹提示弹框
    deleteFolderCancle() {
      this.deleteFolderTo = false;
    },
    // 顶部实时搜素
    realTimeSearch() {
      // value乃输入框值
      this.getDataLists();
    },
    // 顶部搜索选择项
    searchFolder() {},
  },
  computed: {
    ...mapState(["consoleData"]),
    placeholderVal() {
      return `${this.$i18n.t("label_tabpage_findz")} ${this.labelTitle}...`;
    },
    // 报表权限信息
    reportOperation: function () {
      return this.$store.getters.getReportOperation;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/table.scss";

.body {
  height: 100%;
  padding: 10px;
  font-size: 12px !important;
  color: #080707 !important;
}

.reportList {
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  .reportList_content {
    height: 100%;
    .reportList_top {
      padding: 10px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f8f8f8;
      border-bottom: 1px solid #dddbda;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .top_tltle {
        color: #222222;
        div:nth-child(2) {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .right_infos {
        display: flex;
        align-items: center;
        .top_search {
          margin-right: 10px;
          display: flex;
          //下拉框的样式
          ::v-deep .el-input-group__prepend {
            background-color: #fff;
            .el-input__inner {
              width: 120px !important;
            }
          }
          ::v-deep .el-input__inner {
            width: 240px;
            font-size: 12px;
            height: 30px;
          }
          ::v-deep .el-input__icon {
            line-height: 30px;
          }
          ::v-deep .el-input__suffix {
            left: 0;
            text-align: right;
          }
          .searchBtn {
            width: 16px;
            color: #888888;
            position: relative;
            top: 8px;
            left: -32px;
            margin: 0 0px;
            cursor: pointer;
            font-size: 14px;
          }
        }
        .top_butts {
          display: flex;
        }
        .set_img {
          margin-left: 11px;
          // border: 1px solid #dddbda;
          border-radius: 4px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img,
          svg {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
        .active_img {
          // border: 1px solid #e89335;
        }
        .set_bounce {
          position: absolute;
          right: 0;
          top: 30px;
          z-index: 100;
          width: 250px;
          // word-wrap:break-word;
          background: #ffffff;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
          .top_title {
            padding: 12px 10px;
            color: #333333;
            font-weight: bold;
            border-bottom: 1px solid #dcdcdc;
            text-align: left;
            word-wrap: break-word;
          }
          .all_fields {
            height: 240px;
            overflow-y: auto;
            color: #333333;
            .fixed_field {
              padding: 12px 10px;
              border-bottom: 1px solid #dcdcdc;
              display: flex;
              justify-content: space-between;
              align-items: center;
              img,
              svg {
                width: 12.4px;
                height: 14px;
                margin-left: 17px;
              }
              ::v-deep .el-checkbox {
                display: flex;
                justify-content: space-between;
                width: 100%;
                flex-direction: row-reverse;
                align-items: flex-end;
              }
            }
            ::v-deep .el-checkbox-group {
              display: flex;
              flex-direction: column;
              .el-checkbox {
                padding: 12px 38px 12px 10px;
                border-bottom: 1px solid #dcdcdc;
                margin-right: 0;
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
              }
            }
            ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
              background: #1b5297;
            }
            ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #333333;
            }
          }
          .bottm_butts {
            height: 60px;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            div {
              width: 54px;
              height: 30px;
              border-radius: 4px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            div:first-child {
              color: #5780b3;
              border: 1px solid #dcdcdc;
              background: #fff;
              margin-right: 5px;
            }
            div:last-child {
              background: #1b5297;
            }
          }
        }
      }
    }
    .reportList_bottom {
      height: calc(100% - 70px);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .left_lists {
        background-color: #fff;
        border: 1px solid rgb(220, 220, 220);
        width: 240px;
        .all_reports {
          .all_data_reports {
            div {
              padding: 11px 20px 10px 17px;
              cursor: pointer;
            }
          }
        }
        .all_folders {
          height: calc(100% - 156px);
          .all_data_folders {
            height: calc(100% - 39px);
            overflow-y: auto;
            .top_folder_datas {
              border-bottom: 1px solid #eeeeee;
              padding: 10px 0;
            }
            .bottom_folder_datas {
              padding-top: 10px;
            }
            .each_folder {
              padding: 11px 20px 10px 17px;
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              .folder_name {
                width: calc(100% - 16px);
              }
              .operation_img {
                display: flex;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }

        .title_div {
          background: #f3f2f2;
          padding: 11px 20px 10px 17px;
          font-weight: bold;
        }
        .placedTop {
          padding: 11px 20px 10px 17px;
          border-bottom: 1px solid #ccc;
        }
      }
      .right_table {
        width: calc(100% - 250px);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ::v-deep a {
          text-decoration: none;
        }
        ::v-deep
          .el-checkbox__input.is-disabled.is-checked
          .el-checkbox__inner {
          background-color: #006dcc !important;
        }
        ::v-deep .el-table {
          overflow-y: auto;
        }
        ::v-deep .el-table::before {
          height: 0;
        }
        .word_style {
          color: #006dcc;
          cursor: pointer;
        }
        ::v-deep a {
          color: #006dcc;
        }

        .operation_butts {
          position: relative;
          cursor: pointer;
          img {
            width: 19px;
            height: 19px;
          }
        }
        .notes {
          color: #006dcc;
          position: relative;
          top: 30px;
          left: 10px;
          .text-red {
            color: #f00;
          }
        }
        .bottom_page {
          height: 66px;
          padding-left: 15px;
          background: #ffffff;
          box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
          border-radius: 0 0 3px 3px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        ::v-deep .el-table__body-wrapper {
          height: calc(100% - 51px) !important;
          overflow-y: auto;
        }
        //滚动条
        ::v-deep ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        ::v-deep ::-webkit-scrollbar-thumb {
          //滑块部分
          border-radius: 0;
          background: #dedcda !important;
          -webkit-box-shadow: none !important;
        }
        ::v-deep ::-webkit-scrollbar-track {
          //轨道部分
          border-radius: 0;
          background: #f1f1f1 !important;
          -webkit-box-shadow: none !important;
        }
      }
    }
    .active {
      color: #006dcc;
      // background: #f3f6f9;
    }
  }
}
.no-data {
  text-align: center;
  margin: 20px auto;
  color: #909399;
}
</style>

<style lang="scss">
.el-popover.myPopover {
  padding: 0 !important;
  min-width: 113px !important;
  .moreMenus {
    .el-card__body {
      padding: 10px;
      ol,
      ul,
      dl {
        margin-bottom: 0;
      }
      li {
        height: 30px;
        line-height: 30px;
        &:hover {
          color: #006dcc;
          cursor: pointer;
        }
        .el-button {
          color: #303133;
        }
        .el-button.is-disabled.el-button--text {
          color: #c0c4cc;
        }
      }
    }
  }
  .folder_operation {
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .el-button {
      margin-left: 0;
      padding: 8px 10px;
      color: #080707;
      border: none;
      text-align: left;
    }
    .el-button.is-disabled {
      color: #c0c4cc;
      border-color: #ebeef5;
    }
  }
}
.blue-color {
  color: #00a0ff;
}
.xingColor {
  color: red;
}
</style>