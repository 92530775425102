<!--
  重命名报表
-->
<template>
  <el-dialog
    :visible="renameReportTo"
    :title="title"
    :before-close="renameReportCancel"
    :close-on-click-modal="false"
    width="490px"
  >
    <div class="center_center">
      <el-form ref="form" :model="form" label-width="">
        <el-form-item
          v-show="titleType === 'rename'"
          :label="$t('label.nameofreport')"
          prop="reportLabel"
          :rules="{
            required: true,
            message: this.$i18n.t('vue_label_report_notice_nameisrequired'),
            trigger: 'blur',
          }"
        >
          <el-input v-model="form.reportLabel"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('label.remarkofreport')"
          v-show="titleType === 'rename'"
        >
          <el-input
            type="textarea"
            :rows="4"
            placeholder
            v-model="form.reportNote"
          ></el-input>
        </el-form-item>
        <!-- 保存位置 -->
        <el-form-item
          :label="$t('vue_label_report_savelocation')"
          v-show="titleType === 'transfer'"
        >
          <el-select
            filterable
            v-model="form.location"
            :placeholder="$t('vue_label_report_selectfolder')"
          >
            <el-option
              v-for="item in allFolders"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="renameReportCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="renameReportConfirm('form')">
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import { updateReportName } from "../api";

export default {
  props: {
    // 控制显示隐藏弹框
    renameReportTo: {
      type: Boolean,
      default: false,
    },
    reportInfos: {
      type: Object,
      default: () => {},
    },
    // 获取报表列表方法
    getDataLists: {
      type: Function,
      default: () => {},
    },
    allFolders: {
      type: Array,
      default: () => [],
    },
    titleType: {
      // 对报表的重命名、转移文件夹
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        reportLabel: "",
        reportNote: "",
        location: "", // 文件夹id
      },
    };
  },
  computed: {
    // 计算title
    title() {
      if (this.titleType === "rename") {
        // 重命名
        return this.$i18n.t("label.rename");
      } else if (this.titleType === "transfer") {
        // 转移文件夹
        return this.$i18n.t("vue_report_dashboard_transfer");
      }
      return "";
    },
  },
  watch: {
    reportInfos: function () {
      this.form.reportLabel = this.reportInfos.reportLabel;
      this.form.reportNote = this.reportInfos.description;
      this.form.location = this.reportInfos.folderId;
    },
  },
  methods: {
    renameReportCancel() {
      this.$emit("renameReportCancel");
    },
    renameReportConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateReportNames();
        } else {
          return false;
        }
      });
    },
    // 重命名报表
    async updateReportNames() {
      let params = {
        description: this.form.reportNote,
        id: this.reportInfos.reportId,
        name: this.form.reportLabel,
        reportCode: "",
        reportFolderId: this.form.location,
        saveAs: false,
      };
      await updateReportName(params);
      this.getDataLists();
      this.$emit("renameReportCancel");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/bouncedForm.scss";
</style>