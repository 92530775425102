<!--
  删除文件夹提示弹框
-->
<template>
  <el-dialog
    :visible="deleteFolderTo"
    :title="$t('label.emailmerge.list.deletefolder')"
    :before-close="deleteFolderCancle"
    :close-on-click-modal="false"
    width="490px"
  >
    <div class="center_center">
      <div>
        {{ $t("label.file.folder.delete3", { foldername: folderInfo.name }) }}
      </div>
      <div>{{ $t("vue_label_norm_undone") }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deleteFolderCancle">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="deleteFolderConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deleteReportFolder } from "../api";

export default {
  props: {
    // 控制显示隐藏弹框
    deleteFolderTo: {
      type: Boolean,
      default: false,
    },
    folderInfo: {
      type: Object,
      default: () => {},
    },
    // 获取文件夹接口
    getFolders: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    // 点击取消
    deleteFolderCancle() {
      this.$emit("deleteFolderCancle");
    },
    // 确认删除
    deleteFolderConfirm() {
      let params = {
        id: this.folderInfo.id,
      };
      deleteReportFolder(params)
        .then(() => {
          this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
          this.$emit("deleteFolderCancle");
          this.getFolders();
        })
        .catch(() => {
          this.$emit("deleteFolderCancle");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.center_center {
  div:first-child {
    color: #686868;
  }
  div:last-child {
    color: #ff3c32;
  }
}
</style>